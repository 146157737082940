import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PaymentFormService } from 'src/app/_admin-services/payment-form.service';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit {

  paymentform: FormGroup;
  form: any
  isSaving: boolean = false

  constructor(private formBuilder: FormBuilder, private service: PaymentFormService) {}

  ngOnInit() {
    this.paymentform = this.formBuilder.group({
      account_name: ['', Validators.required],
      bdo_account_number: ['', Validators.required],
      bpi_account_number: ['', Validators.required],
      pnb_account_number: ['', Validators.required],
      receiver_name: ['', Validators.required],
      receiver_address: ['', Validators.required],
      receiver_no: ['', Validators.required]
    })
    this.getForm()
  }

  getForm(){
    this.service.getForm().subscribe(res => {
      let tmp: any = res
      console.log('PaymentFormComponent', tmp)
      this.form = tmp.data.items[0]
      this.paymentform.controls['account_name'].setValue(this.form.account_name)
      this.paymentform.controls['bdo_account_number'].setValue(this.form.bdo_account_number)
      this.paymentform.controls['bpi_account_number'].setValue(this.form.bpi_account_number)
      this.paymentform.controls['pnb_account_number'].setValue(this.form.pnb_account_number)
      this.paymentform.controls['receiver_name'].setValue(this.form.receiver_name)
      this.paymentform.controls['receiver_address'].setValue(this.form.receiver_address)
      this.paymentform.controls['receiver_no'].setValue(this.form.receiver_no)
    })
  }

  save(){
    if(this.isSaving) return
    if (this.paymentform.invalid) {
      alert('Please complete the payment information form')
      return
    }
    let data = this.paymentform.getRawValue()
    data.type=''

    this.isSaving = true
    this.service.saveInfo(data).subscribe(res => {
      this.getForm()
      this.isSaving = false
    })
  }

}
