import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { TermsDialogComponent } from '../terms-dialog/terms-dialog.component';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import * as places from '../shared/cities.json'
import * as lbc from '../shared/lbc_branches.json'

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {

  defval: string = 'deliver'
  placeVal: string = 'NCR'
  selTime: string = ''
  selDate: string = ''
  items: any[] = []
  total: number = 0
  lbc_branch = lbc.default
  lbc_name: string = lbc.default[0].branch_name
  filteredOptions: Observable<string[]>;
  myControl = new FormControl();

  checked: boolean = false
  to: string = ""
  from: string = ""
  message: string = ""

  constructor(private router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    console.log('ShoppingCartComponent', sessionStorage.getItem('mCart'))
    if (sessionStorage.getItem('deliveryOpt')) {
      let body = JSON.parse(sessionStorage.getItem('deliveryOpt'))
      this.defval = body.defval
      this.placeVal = body.placeVal
      this.selTime = body.selTime
      this.selDate = body.selDate
      this.checked = body.checked
      this.to = body.to
      this.from = body.from
      this.message = body.message
    }
    let data = sessionStorage.getItem('mCart')
    if (data) {
      this.items = JSON.parse(data)
      this.getTotal()
    }

    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  purchase() {
    if (this.defval === 'pick') {
      if (this.selTime === '' || this.selDate === '') {
        alert('Please provide time and date of pickup')
        return
      }
    }
    if(this.defval === 'lbc'){
      let isExist = false
      for(let b of this.lbc_branch){
        if(b.branch_name.toLowerCase() === this.myControl.value.toLowerCase()) isExist = true
      }
      if(!isExist){
        alert("Invalid LBC branch")
        return
      }
    }
    let body = {
      'defval': this.defval,
      'placeVal': this.placeVal,
      'selTime': this.selTime,
      'selDate': this.selDate,
      'selBranch': this.myControl.value,
      'checked': this.checked,
      'to': this.to,
      'from': this.from,
      'message': this.message
    }
    console.log('purchase', body)
    sessionStorage.setItem('deliveryOpt', JSON.stringify(body))
    sessionStorage.setItem('mCart', JSON.stringify(this.items))
    this.router.navigate(['./purchase-procedure']);
  }

  shop() {
    let data = sessionStorage.getItem('mCart')
    if (data) {
      let tmp = JSON.parse(data)
      if (tmp.length != this.items.length)
        if (confirm('Do you want to save the changes?'))
          this.saveChanges()
      for (let x = 0; x < tmp.length; x++)
        if (tmp[x].qty != this.items[x].qty)
          if (confirm('Do you want to save the changes?'))
            this.saveChanges()
    }
    this.router.navigate(['']);
  }

  saveChanges() {
    sessionStorage.setItem('mCart', JSON.stringify(this.items))
    this.router.navigate(['']);
  }

  delete(i) {
    if (confirm('Do you want to delete this item?')) {
      this.items.splice(i, 1)
      sessionStorage.setItem('mCart', JSON.stringify(this.items))
    }
  }

  getTotal() {
    this.total = 0
    for (let itm of this.items) {
      this.total += (itm.qty * (itm.item.price + (this.defval === 'deliver' ? this.getFee(itm) : 0))) + (this.checked?500:0)
    }
  }

  getFee(itm) {
    let x = 'ncr'
    if (this.placeVal === 'LUZON') x = 'luz'
    else if (this.placeVal === 'VISAYAS') x = 'vis'
    else if (this.placeVal === 'MINDANAO') x = 'min'
    return itm.item['shipping_fee_' + x]
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.lbc_branch.filter(option => option.branch_name.toLowerCase().includes(filterValue));
  }

}
