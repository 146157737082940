import { first } from 'rxjs/operators';
import { SharedDataService } from '.././../../shared/shared-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Transaction } from 'src/app/user-routes/shared/transaction.model';
import { OrderRequest } from '../../shared/admin.model';
import { OrderDialogComponent } from '../../order-dialog/order-dialog.component';

import { NoResultComponent } from './modals/no-result/no-result.component';

import { ItemModalComponent } from './modals/item-modal/item-modal.component';

import { ItemsService, ItemCategService } from '../../../_admin-services';

import { FormControl } from '@angular/forms';

import * as $ from 'jquery';
import * as moment from 'moment';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { ItemsFormComponent } from '../form/form.component';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss']
})
export class ItemsListsComponent implements OnInit {

  date = new FormControl(new Date());
  date_To = new FormControl('');
  date_From = new FormControl('');
  itemCategory: any;
  @ViewChild('editTemplate', { read: true, static: false }) sort: MatSort;

  reqList: OrderRequest[] = [];
  dataSource: MatTableDataSource<OrderRequest>;
  displayedColumns = ['id', 'item_no', 'name', 'category', 'viewAction'];

  constructor(private sharedData: SharedDataService, public dialog: MatDialog,
    private itemService: ItemsService, private itemCategService: ItemCategService,
    private router: Router) { }

  search_loading = false;
  paginator = {
    page: 1,
    limit: '20',
    key: '_all',
    type: '0'
  };

  pageItem = {
    first: 1,
    total: 0,
    pages: 0,
    has_next: 0,
    has_previous: 0,
    next_page: 0,
    previous_page: 0,
    offset: 0,
    current: 0
  };

  isObjectEmpty(Obj: any) {
    for (const key in Obj) {
      if (Obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  ngOnInit() {
    this.getData(this.paginator);
    this.dataSource = new MatTableDataSource(this.reqList);
    this.dataSource.sort = this.sort;
  }

  getData(pgn: any) {

    const str = $.param(pgn);
    this.itemService.getAll(str)
      .subscribe(data => {
        this.search_loading = false;
        const d: any = data;
        if (!this.isObjectEmpty(d.data.items)) {
          this.pageItem = d.data.pagination;
          this.reqList = d.data.items;
          console.log(this.reqList)
          this.dataSource.data = this.reqList;
        } else {
          this.openDialog();
        }
      });
  }

  selectLimit(value: any) {
    this.paginator.limit = value;
    this.getData(this.paginator);
  }

  selectType(event: any) {
    this.paginator.type = event;
  }
  pageAction(action: string) {
    switch (action) {
      case 'first': {
        this.paginator.page = 1;
        break;
      }
      case 'prev': {
        this.paginator.page = this.pageItem.previous_page;
        break;
      }
      case 'next': {
        this.paginator.page = this.pageItem.next_page;
        break;
      }
      case 'last': {
        this.paginator.page = this.pageItem.pages;
        break;
      }
    }

    this.getData(this.paginator);
  }

  applyFilter(key: string) {
    if (key) {
      this.paginator.page = 1;
      this.paginator.key = key;
      this.getData(this.paginator);
    } else {
      this.paginator.page = 1;
      this.paginator.key = '';
      this.getData(this.paginator);
    }
  }

  delete(id) {
    if (confirm('Are you sure you want to delete this item?')) {
      this.itemService.delete(id).subscribe(data => {
        const d: any = data;
        this.getData(this.paginator);
      });
    }
  }

  getItemCateg() {
    this.itemCategService.getAll()
      .subscribe(data => {
        const d: any = data;
        if (!this.isObjectEmpty(d.data.items)) {
          this.itemCategory = d.data.items;
        }
      })
  }

  edit(data) {
    const dialogRef = this.dialog.open(ItemsFormComponent, {
      width: '80%', height: '800px',
      data: data,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      this.getData(this.paginator);
    });
  }

  openView(row: any, option: string) {
    row.action = 'view'
    const dialogRef = this.dialog.open(ItemsFormComponent, {
      width: '80%', height: '800px',
      data: row,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getData(this.paginator);
      }
    });
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(NoResultComponent, {
      width: '250px',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  date_FromChange(value: any) {
    this.paginator['date_from'] = moment(new Date(value)).format('YYYY-MM-DD');
    console.log(this.paginator);
  }

  date_ToChange(value: any) {
    this.paginator['date_to'] = moment(new Date(value)).format('YYYY-MM-DD');
    console.log(this.paginator);
  }

  additem() {
    const dialogRef = this.dialog.open(ItemsFormComponent, {
      width: '80%', height: '800px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      this.getData(this.paginator);
    });
  }

}
