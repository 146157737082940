import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// import { OrderService } from '../../_admin-services';
import { first } from 'rxjs/operators';
import { id } from '@swimlane/ngx-charts/release/utils';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {

  details: any;

  constructor(
    public dialogRef: MatDialogRef<UserModalComponent>,
    // private orderService: OrderService,
    private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data) {
    this.details = data;
  }

  ngOnInit() {
    console.log(this.details)
  }

}
