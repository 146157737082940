import { first } from 'rxjs/operators';
import { SharedDataService } from '.././../shared/shared-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Transaction } from 'src/app/user-routes/shared/transaction.model';
import { GuideModel } from '../shared/guide.model';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';

import { ItemCategService, SettingService } from '../../_admin-services';

import { FormControl } from '@angular/forms';

import * as $ from 'jquery';
import * as moment from 'moment';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { NoResultComponent } from '../items/lists/modals/no-result/no-result.component';
import { GuideFormComponent } from './guide-form/guide-form.component';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {

  @ViewChild('editTemplate', { read: true, static: false }) sort: MatSort;

  reqList: GuideModel[] = [];
  dataSource: MatTableDataSource<GuideModel>;
  displayedColumns = ['id', 'title', 'description', 'viewAction'];
  search_loading = false;


  constructor(private sharedData: SharedDataService, public dialog: MatDialog, private settingService: SettingService,
    private router: Router) { }


  ngOnInit() {
    this.getData();
    this.dataSource = new MatTableDataSource(this.reqList);
    this.dataSource.sort = this.sort;
  }

  getData() {

    this.settingService.getAllGuide()
    .subscribe( data => {
      this.search_loading = false;
      const d: any = data;
      if (!this.isObjectEmpty(d.data.items)) {
        this.reqList = d.data.items;
        console.log( this.reqList);
        this.dataSource.data = this.reqList;
      } else {
        this.openDialog();
      }
    });
  }

  addGuide() {
    const dialogRef = this.dialog.open(GuideFormComponent, {
      width: '650px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getData();
    });
  }

  delete(id: any) {
    if (confirm('Are you sure you want to delete this item?')) {
      this.settingService.deleteGuide(id).subscribe(data => {
        const d: any = data;
        this.getData();
      });
    }
  }

  edit(data) {
    const dialogRef = this.dialog.open(GuideFormComponent, {
      width: '650px',
      data : data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getData();
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NoResultComponent, {
      width: '250px',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

  isObjectEmpty (Obj: any) {
    for (const key in Obj) {
      if (Obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

}
