import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import * as places from '../shared/cities.json'
import * as lbc from '../shared/lbc_branches.json'
import { OrderingService } from '../shared/ordering.service.js';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { TermsConditionComponent } from 'src/app/static-pages/terms-condition/terms-condition.component.js';
import { MatDialog } from '@angular/material';
import { TermsDialogComponent } from '../terms-dialog/terms-dialog.component';
import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component';

@Component({
  selector: 'app-purchase-proc',
  templateUrl: './purchase-proc.component.html',
  styleUrls: ['./purchase-proc.component.scss']
})
export class PurchaseProcComponent implements OnInit {

  orderForm: FormGroup

  isNewAddress = 2
  defval: string = 'deliver'
  placeVal: string = 'ncr'
  selTime: string = ''
  selDate: string = ''
  items: any[] = []
  currentUser: any
  selPayment = 0

  place = places.default
  lbc_branch = lbc.default
  lbc_name: string = lbc.default[0].branch_name
  filteredOptions: Observable<string[]>;
  myControl = new FormControl();

  mcit: any[] = []
  mreg: any[] = []
  total: number = 0
  ttlFee: number = 0

  saving: boolean = false

  checked = false

  giftChecked: boolean = false
  to: string = ""
  from: string = ""
  message: string = ""

  constructor(private service: OrderingService, private formBuilder: FormBuilder, private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    let data = sessionStorage.getItem('mCart')
    this.createForm()
    if (data) {
      this.items = JSON.parse(data)
      this.getTotal()
    }
    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  createForm() {
    if (sessionStorage.getItem('deliveryOpt')) {
      let body = JSON.parse(sessionStorage.getItem('deliveryOpt'))
      console.log('createForm', body)
      this.defval = body.defval
      this.placeVal = body.placeVal
      this.selTime = body.selTime
      this.selDate = body.selDate
      this.giftChecked = body.checked
      this.to = body.to
      this.from = body.from
      this.message = body.message
      this.myControl.setValue(body.selBranch)
    }
    let g = []
    let ordOpt = JSON.parse(sessionStorage.getItem('deliveryOpt'))
    if (ordOpt) {
      this.defval = ordOpt.defval
      for (let p of this.place) {
        if (ordOpt.placeVal === p.delivery_name) {
          g = p
        }
      }
    }
    this.orderForm = this.formBuilder.group({
      firstname: [''],
      lastname: [''],
      destination: [g],
      region: [[]],
      city: [[]],
      address: [''],
      postal_code: [''],
      contact_no: [''],
      remarks: ['']
    })
  }

  getTotal() {
    this.total = this.giftChecked?500:0
    this.ttlFee = 0
    for (let itm of this.items)
      this.total += itm.qty * (itm.item.price + (this.defval === 'deliver' ? this.getFee(itm) : 0))
    for (let itm of this.items)
      this.ttlFee += itm.qty * (this.defval === 'deliver' ? this.getFee(itm) : 0)
  }

  getFee(itm) {
    let x = 'ncr'
    let data = this.orderForm.getRawValue()
    if (data.destination.delivery_name === 'LUZON') x = 'luz'
    else if (data.destination.delivery_name === 'VISAYAS') x = 'vis'
    else if (data.destination.delivery_name === 'MINDANAO') x = 'min'
    return itm.item['shipping_fee_' + x]
  }

  purchase() {
    if(this.selPayment == 0){
      alert("Please select mode of payment")
      return
    }
    if (!this.checked) {
      alert("Please agree to our terms and condition")
      return
    }
    let data = this.orderForm.getRawValue()
    if (this.items.length == 0) {
      alert('You have no item to purchase')
      return
    }
    if (this.isNewAddress == 2 && this.defval === 'deliver') {
      if (this.mreg.length == 0 || this.mcit.length == 0) {
        alert('Invalid form inputs please try again')
        return
      }
      data.ship_fee = this.ttlFee
      data.pickup_date = this.selDate
      data.pickup_time = this.selTime
      data.shipping_fee_type = this.getFeeType()
      data.city = data.city.city_name ? data.city.city_name : ''
      data.region = data.region.region_name
    } else {
      data.ship_fee = 0
      data.pickup_date = ''
      data.pickup_time = ''
      data.shipping_fee_type = 0
      data.city = ''
      data.region = ''
    }
    // this.saving = true
    let arr: any[] = []
    for (let itm of this.items)
      arr.push({
        "item_id": itm.item.id,
        "quantity": itm.qty
      })
    if(this.defval === 'lbc'){
      let isExist = false
      for(let b of this.lbc_branch){
        if(b.branch_name.toLowerCase() === this.myControl.value.toLowerCase()) isExist = true
      }
      if(!isExist){
        alert("Invalid LBC branch")
        return
      }
    }
    data.order_item = arr
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    data.user_id = this.currentUser.id
    data.service_type = this.defval === 'deliver' ? 1 : this.defval === 'pick' ? 2 : this.defval === 'lbc' ? 3 : 0
    let mop = ['CEBUANA', 'LBC', 'PNB', 'BDO', 'BPI']
    data.modeofpayment = mop[this.selPayment - 1]
    data.tracking_no = 0
    data.pickup_location = this.myControl.value
    data.courier = 1
    data.est_deliverydate = '2019-06-19'
    data.total_amt = this.total
    data.receiver_type = this.isNewAddress
    data.greeting_msg = "greeting"
    data.gift_to = this.to
    data.gift_from = this.from
    data.gift_message = this.message
    data.giftwrap_fee = 500
    delete data.destination
    console.log('SAVEORDER', data)
    this.service.saveOrder(data).subscribe(
      res => {
        alert('Item waiting for approval')
        sessionStorage.removeItem('mCart')
        sessionStorage.removeItem('deliveryOpt')
        this.router.navigate(['./transactions']);
      }
    )
  }

  getFeeType() {
    let type = 1
    if (this.placeVal === 'LUZON') type = 2
    else if (this.placeVal === 'VISAYAS') type = 3
    else if (this.placeVal === 'MINDANAO') type = 4
    return type
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.lbc_branch.filter(option => option.branch_name.toLowerCase().includes(filterValue));
  }

  viewTerm(){

    const dialogRef = this.dialog.open(TermsDialogComponent, {
      width: '80%', height: '80%',
      data: ''
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // this.orderPagination(this.paginator);
      }
    });
  }

  viewPayment(p){
    const dialogRef = this.dialog.open(PaymentDialogComponent, {
      width: '1100px', height: '90%', minWidth: '1100px',
      data: {
        ind: p,
        total: this.total
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // this.orderPagination(this.paginator);
      }
    });
  }

}
