import { Component, OnInit } from '@angular/core';
import { ResetPasswordPrv } from '../shared/reserpassword.provider';
import { UsersService } from '../../_admin-services';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-forgot-password-reset',
  templateUrl: './forgot-password-reset.component.html',
  styleUrls: ['./forgot-password-reset.component.scss']
})
export class ForgotPasswordResetComponent implements OnInit {

  pass1 = '';
  pass2 = '';

  constructor(
    private usersService: UsersService,
    private rData: ResetPasswordPrv,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    if ( !this.rData.data) {
      this.router.navigate(['/']);
    }
  }

  resetPass() {
    if (this.pass1 !== this.pass2  ) {
      alert('Password does not match!');
      return;
    }

    if (!this.pass1 || !this.pass2  ) {
      alert('Invalid password');
      return;
    }

    const data = {
      'password' : this.pass1,
      'pwd_url' : this.rData.data.pwd_url,
      'email_address' : this.rData.data.email
    };

    this.usersService.passwordReset(data).subscribe(res => {
      alert('Successfully updated');
      this.pass1 = '';
      this.pass2 = '';
      return;
    });
  }

}
