import { PrivacyPolicyComponent } from './static-pages/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './static-pages/contact-us/contact-us.component';
import { AboutUsComponent } from './static-pages/about-us/about-us.component';
import { AnalyticsComponent } from './admin-routes/analytics/analytics.component';
import { AdminRoutesComponent } from './admin-routes/admin-routes.component';
import { UserInfoComponent } from './user-routes/user-info/user-info.component';
import { TransactioViewComponent } from './user-routes/transactio-view/transactio-view.component';
import { PurchaseProcComponent } from './ordering-routes/purchase-proc/purchase-proc.component';
import { ShoppingCartComponent } from './ordering-routes/shopping-cart/shopping-cart.component';
import { ItemDetailsComponent } from './ordering-routes/item-details/item-details.component';
import { UsertypeGuard } from './shared/usertype.guard';
import { UserSigninComponent } from './user-routes/user-signin/user-signin.component';
import { HeaderPageComponent } from './ordering-routes/header-page/header-page.component';
import { LandingPageComponent } from './ordering-routes/landing-page/landing-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { OrderRequestComponent } from './admin-routes/order-request/order-request.component';
import { TransactionComponent } from './admin-routes/transaction/transaction.component';

import { SigninRouterComponent } from './user-routes/signin-router/signin-router.component';
import { UserSignupComponent } from './user-routes/user-signup/user-signup.component';
import { TransactionListComponent } from './user-routes/transaction-list/transaction-list.component';

import { UserListComponent } from './admin-routes/users/list/list.component';
// import { UserFormComponent } from './admin-routes/users/form/form.component';
import { ItemsListsComponent } from './admin-routes/items/lists/lists.component';
import { ItemsFormComponent } from './admin-routes/items/form/form.component';
import { PrintOrComponent } from './admin-routes/print-or/print-or.component';
import { ItemCategListComponent } from './admin-routes/item-category/item-categ-list/item-categ-list.component';
import { RetexPolicyComponent } from './static-pages/retex-policy/retex-policy.component';
import { TermsConditionComponent } from './static-pages/terms-condition/terms-condition.component';
import { ResetPasswordVerifyComponent } from './user-routes/reset-password-verify/reset-password-verify.component';
import { ForgotPasswordComponent } from './user-routes/forgot-password/forgot-password.component';
import { ForgotPasswordResetComponent } from './user-routes/forgot-password-reset/forgot-password-reset.component';
import { PaymentFormComponent } from './admin-routes/payment-form/payment-form.component';
import { AboutComponent } from './admin-routes/about/about.component';
import { GuideComponent } from './admin-routes/guide/guide.component';
import { AdminLandingPageComponent } from './admin-routes/admin-landing-page/admin-landing-page.component';
import { ReturnExchangeFormComponent } from './admin-routes/return-exchange/return-exchange-form/return-exchange-form.component';
import { ClientGuideComponent } from './static-pages/client-guide/client-guide.component';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '', component: HeaderPageComponent,
    children: [
      { path: '', redirectTo: '..', pathMatch: 'full' },
      { path: '..', component: LandingPageComponent },
      { path: 'reset-password/edit', component: ForgotPasswordResetComponent },
      {
        path: 'user', component: SigninRouterComponent,
        children: [
          { path: '', redirectTo: 'signin', pathMatch: 'full' },
          { path: 'signin', component: UserSigninComponent },
          { path: 'admin', component: UserSigninComponent },
          { path: 'signup', component: UserSignupComponent },
          { path: 'forgot-password', component: ForgotPasswordComponent },
          { path: 'reset-password-verify/:token', component: ResetPasswordVerifyComponent },
          // { path: 'reset-password/edit', component: ForgotPasswordResetComponent }
        ]
      },
      { path: 'item-info/:item_slug', component: ItemDetailsComponent },
      { path: 'shopping-cart', component: ShoppingCartComponent },
      { path: 'purchase-procedure', component: PurchaseProcComponent, canActivate: [UsertypeGuard] },
      { path: 'transactions', component: TransactionListComponent, canActivate: [UsertypeGuard] },
      { path: 'transaction-view', component: TransactioViewComponent, canActivate: [UsertypeGuard] },
      { path: 'user-info', component: UserInfoComponent, canActivate: [UsertypeGuard],
        children: [
          { path: 'update', component: UserSignupComponent }
        ] },
      { path: 'aboutus', component: AboutUsComponent },
      { path: 'contactus', component: ContactUsComponent },
      { path: 'guides', component: ClientGuideComponent},
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'return-exchange-policy', component: RetexPolicyComponent },
      { path: 'terms-condition', component: TermsConditionComponent }
    ]
  },
  {
    path: 'admin', component: AdminRoutesComponent, canActivateChild: [UsertypeGuard],
    children: [
      { path: '', redirectTo: 'order-request', pathMatch: 'full', },
      { path: 'order-request', component: OrderRequestComponent },
      { path: 'analytics', component: AnalyticsComponent },
      { path: 'transaction', component: TransactionComponent },
      { path: 'users', component: UserListComponent },
      { path: 'items', component: ItemsListsComponent },
      { path: 'itemCategory', component: ItemCategListComponent },
      { path: 'payment-form', component: PaymentFormComponent },
      { path: 'landing-page', component: AdminLandingPageComponent },
      { path: 'about', component: AboutComponent },
      { path: 'guide', component: GuideComponent },
      { path: 'return-exchange', component: ReturnExchangeFormComponent },
    ]
  },
  { path: 'print-or/:ord_no', component: PrintOrComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
