import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTP_API_URL } from '../shared/apis.service';
import { count } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ItemsService {

  constructor(private http: HttpClient) {}

  getAll(data: any) {
    return this.http.get(`${HTTP_API_URL}item?${data}`);
  }

  get(id: any) {
    return this.http.get(`${HTTP_API_URL}item/${id}`);
  }

  delete(id: any) {
    return this.http.delete(`${HTTP_API_URL}item?id=${id}`);
  }

  update(body, files: Set<File>, inst_files: Set<File>, id) {

    const formData: FormData = new FormData();

    formData.append('itemcateg_id', body.itemcateg_id);
    formData.append('name', body.name);
    formData.append('description', body.description);
    formData.append('min_stock', body.min_stock);
    formData.append('current_stock', body.current_stock);
    formData.append('price', body.price);
    formData.append('length', body.length);
    formData.append('width', body.width);
    formData.append('height', body.height);
    formData.append('weight', body.weight);
    formData.append('remarks', body.remarks);
    formData.append('shipping_fee_ncr', body.shipping_fee_ncr);
    formData.append('shipping_fee_luz', body.shipping_fee_luz);
    formData.append('shipping_fee_vis', body.shipping_fee_vis);
    formData.append('shipping_fee_min', body.shipping_fee_min);
    formData.append('has_file', body.has_file);
    formData.append('new_id_image', body.new_id_image);
    formData.append('inst_new_id_image', body.inst_new_id_image);
    formData.append('item_slug', body.item_slug);
    let counter = 0;
    files.forEach(file => {
      counter ++;
      formData.append(`image_${counter}`, file);
    });

    body.file_count = counter;

    formData.append('file_count', body.file_count);

    let x = 0;
    inst_files.forEach(inst_file => {
      x ++;
      formData.append(`instruction_image_${x}`, inst_file);
    });

    body.instruction_image_count = x;
    formData.append('instruction_image_count', body.instruction_image_count);

    return this.http.put(`${HTTP_API_URL}item?id=${id}`, formData );
  }

  create(body, files: Set<File>, inst_files: Set<File>) {

      const formData: FormData = new FormData();

      formData.append('itemcateg_id', body.itemcateg_id);
      formData.append('name', body.name);
      formData.append('description', body.description);
      formData.append('min_stock', body.min_stock);
      formData.append('current_stock', body.current_stock);
      formData.append('price', body.price);
      formData.append('length', body.length);
      formData.append('width', body.width);
      formData.append('height', body.height);
      formData.append('weight', body.weight);
      formData.append('remarks', body.remarks);
      formData.append('shipping_fee_ncr', body.shipping_fee_ncr);
      formData.append('shipping_fee_luz', body.shipping_fee_luz);
      formData.append('shipping_fee_vis', body.shipping_fee_vis);
      formData.append('shipping_fee_min', body.shipping_fee_min);
      formData.append('has_file', body.has_file);
      formData.append('item_slug', body.item_slug);
      let counter = 0;
      files.forEach(file => {
        counter ++;
        formData.append(`image_${counter}`, file);
      });

      body.file_count = counter;

      formData.append('file_count', body.file_count);

      let x = 0;
      inst_files.forEach(inst_file => {
        x ++;
        formData.append(`instruction_image_${x}`, inst_file);
      });

      body.instruction_image_count = x;
      formData.append('instruction_image_count', body.instruction_image_count);

      return this.http.post(`${HTTP_API_URL}item`, formData );

  }

}
