import { ApisService } from './../shared/apis.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTP_API_URL } from '../shared/apis.service';

@Injectable({ providedIn: 'root' })
export class SettingService {

  constructor(private http: HttpClient, private service: ApisService) {}

  getAllGuide() {
    return this.http.get(`${HTTP_API_URL}setting/guide`);
  }

  deleteGuide(id: any) {
    return this.http.delete(`${HTTP_API_URL}setting/guide?id=${id}`);
  }

  getGuide(id: any) {
    return this.http.get(`${HTTP_API_URL}setting/guide?id=${id}`);
  }

  createGuide(data: any) {
    return this.http.post(`${HTTP_API_URL}setting/guide`, data);
  }

  updateGuide(data: any, id: string) {
    return this.http.put(`${HTTP_API_URL}setting/guide?id=${id}`, data);
  }

  //
  uploadFiles(body, description, files: Set<File>) {

    const formData: FormData = new FormData();
    formData.append('has_file', body.has_file);
    formData.append('file_type', body.file_type);

    let counter = 0;
    files.forEach(file => {
      counter ++;
      formData.append(`image_${counter}`, file);
    });

    let x = 0;
    description.forEach(d => {
      x ++;
      formData.append(`description_${counter}`, d);
    });

    body.file_count = counter;
    formData.append('file_count', body.file_count);
    return this.http.post(`${HTTP_API_URL}setting/image-upload`, formData );

  }

  getUploadedFiles(homePage) {
    return this.service.get(`${HTTP_API_URL}setting/image-upload?file_type=${homePage}`);
  }

  deleteImage(id) {
    return this.http.delete(`${HTTP_API_URL}setting/image-upload?id=${id}`);
  }

  updateImageDesc(id, data) {
    return this.http.put(`${HTTP_API_URL}setting/image-upload?id=${id}`, data);
  }
}
