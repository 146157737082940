import { ApisService, signinURL, saveItemURL, addClient, userUrl, custOrder, getOrderID, proofPaymentURL, remarksUrl, resetPass, HTTP_API_URL } from './../../shared/apis.service';
import { Injectable } from '@angular/core';
import * as jwt_decode from "jwt-decode";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  isSignedIn = false;
  currentUser: any;

  constructor(private apiService: ApisService, private http: HttpClient) { }

  setUser(token: any) {
    let tokenData: any = this.tokenData(token)
    console.log('TOKEN', tokenData)
    let body = {
      id: tokenData.user_info.id,
      username: tokenData.user_info.username,
      email_address: tokenData.user_info.email_address,
      contact_no: tokenData.user_info.contact_no,
      img_profile: tokenData.user_info.img_profile,
      firstname: tokenData.user_info.firstname,
      middlename: tokenData.user_info.middlename,
      surename: tokenData.user_info.surename,
      usertype_id: tokenData.user_info.usertype_id
    }
    this.currentUser = body
    localStorage.setItem('currentUser', JSON.stringify(body))
    localStorage.setItem('g-retail-token', token)
  }

  tokenData(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  getToken(): string {
    return localStorage.getItem('g-retail-token');
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if(!token) token = this.getToken();
    if(!token) return true;

    const date = this.getTokenExpirationDate(token);
    if(date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  hasCurrentUser() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser != null) {
      this.isSignedIn = this.currentUser.isSignedIn;
      return true;
    }

    return false;
  }

  signout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('g-retail-token');
  }

  // API SERVICE CALLS

  signin(body: any) {
    return this.apiService.post(signinURL, body);
  }

  insertItem(body: any) {
    return this.apiService.post(saveItemURL, body);
  }

  inserRemarks(body: any) {
    return this.apiService.post(remarksUrl, body)
  }

  insertClient(body: any, files: any) {

    const formData: FormData = new FormData()

    formData.append('usertype_id', '1')
    formData.append('username', body.username)
    formData.append('email_address', body.email_address)
    formData.append('password', body.password)
    formData.append('firstname', body.firstname)
    formData.append('middlename', body.middlename)
    formData.append('surename', body.surename)
    formData.append('contact_no', body.contact_no)
    formData.append('has_file', body.has_file)
    formData.append('file_count', '1')
    formData.append('img_profile', files)
    formData.append('age', body.age)
    formData.append('gender', body.gender)
    formData.append('destination', body.destination)
    formData.append('region', body.region)
    formData.append('city', body.city)
    formData.append('address', body.address)
    return this.apiService.insertClient(addClient, formData);
  }

  clientUpdate(body, files: any, id){

    const formData: FormData = new FormData();

    formData.append('usertype_id', body.usertype_id);
    formData.append('username', body.username);
    formData.append('email_address', body.email_address);
    // formData.append('password', body.password);
    formData.append('firstname', body.firstname);
    formData.append('middlename', body.middlename);
    formData.append('surename', body.surename);
    formData.append('contact_no', body.contact_no);
    formData.append('age', body.age);
    formData.append('gender', body.gender);
    formData.append('address', body.address);
    formData.append('has_file', "false");
    formData.append('file_count', '0');
    if(files){
      formData.append('has_file', "true")
      formData.append('file_count', '1')
      formData.append('img_profile', files)
    }
    formData.append('city', body.city);
    formData.append('destination', body.destination);
    formData.append('region', body.region);
    // formData.append('img_profile', files)

    return this.apiService.put(`${userUrl}?id=${id}`, formData );
  }

  getUser(id: number) {
    return this.apiService.get(userUrl + '/' + id)
  }

  getTransaction(id) {
    return this.apiService.get(custOrder + id)
  }

  getOrderTransaction(id) {
    return this.apiService.get(getOrderID + id)
  }

  setProofPayment(files: any, id) {
    const formData: FormData = new FormData()
    formData.append('has_file', 'true')
    formData.append('proofofpayment', files)
    return this.apiService.uploadImage(proofPaymentURL + id, formData)
  }

  uploadTestimony(body, files: Set<File>){

    const formData: FormData = new FormData()

    formData.append('item_id', body.item_id+'')
    formData.append('user_id', body.user_id+'')
    formData.append('rate', body.rate+'')
    formData.append('message', body.message)
    formData.append('order_no', body.order_no)

    let counter = 0
    files.forEach(file => {
      counter++
      formData.append(`testimony_image_${counter}`, file)
    })
    
    formData.append('file_count', counter+'')
    formData.append('has_file',counter != 0 ? "true" : 'false')

    return this.http.post(`${HTTP_API_URL}testimony`, formData );

  }

  resetPassword(body: any){
    return this.apiService.post(resetPass, body)
  }

}
