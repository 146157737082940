import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTP_API_URL } from '../shared/apis.service';

@Injectable({ providedIn: 'root' })
export class ItemCategService {

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${HTTP_API_URL}item-category?page=1&limit=1000`);
  }

  delete(id: any) {
    return this.http.delete(`${HTTP_API_URL}item-category?id=${id}`);
  }

  get(id: any) {
    return this.http.get(`${HTTP_API_URL}item-category?id=${id}`);
  }

  create(data: any) {
    return this.http.post(`${HTTP_API_URL}item-category`, data);
  }

  update(data: any, id: string) {
    return this.http.put(`${HTTP_API_URL}item-category?id=${id}`, data);
  }

}
