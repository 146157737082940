import { UserService } from 'src/app/user-routes/shared/user.service';
import { Component, OnInit } from '@angular/core';
import { UserProvider } from '../shared/users.provider';
import { TransactionDesc } from '../shared/transaction.model';
import { map, catchError } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TestimonyDialogComponent } from '../testimony-dialog/testimony-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-transactio-view',
  templateUrl: './transactio-view.component.html',
  styleUrls: ['./transactio-view.component.scss']
})
export class TransactioViewComponent implements OnInit {

  deliveryMeth = ["Delivery", "Pick-up at store"]
  transDesc: TransactionDesc
  imgFile: File[] = []

  isUpload = false

  mRemarks: any[] = []

  constructor(private userProvider: UserProvider, private userService: UserService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getTransDesc()
  }

  localUrl = "";
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);  
      this.imgFile = event.target.files[0];
      // this.isUpload = true
    }
  }

  uploadProof(){
    if(this.isUpload) return
    this.isUpload = true
    this.userService.setProofPayment(this.imgFile, this.transDesc.id).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            return

          case HttpEventType.Response:
            let res: any = event.body
            this.isUpload = false
            alert('Upload finished')
            return
        }
      }),
      catchError((err: HttpErrorResponse) => {
        console.log('HttpErrorResponse', err)
        this.isUpload = false
        return Observable.throw(err);
      })
    ).subscribe()
  }

  getTransDesc() {
    this.mRemarks = []
    this.userService.getOrderTransaction(this.userProvider.transData.order_no).subscribe(
      res => {
        console.log('getTransDesc', res)
        let currentUser = JSON.parse(localStorage.getItem('currentUser'))
        for (let itm of res.data.items[0].order_items)
          this.mRemarks.push({
            item_id: itm.item_id,
            user_id: currentUser.id,
            rate: itm.rate,
            message: itm.message
          })
        this.transDesc = res.data.items[0]
        this.localUrl = this.transDesc.proofofpayment
      }
    )
  }

  saveRemarks() {
    this.userService.inserRemarks(this.mRemarks).subscribe(
      res => {
        this.getTransDesc()
      }
    )
  }

  setTestimony(row){
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    const dialogRef = this.dialog.open(TestimonyDialogComponent, {
      width: '80%', height: '800px',
      data: {
        user_id: currentUser.id,
        item_id: row,
        order_no: this.userProvider.transData.order_no
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      this.getTransDesc()
    });
  }

}
