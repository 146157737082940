import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { OrderService } from '../../_admin-services';
import { first } from 'rxjs/operators';
import { id } from '@swimlane/ngx-charts/release/utils';
import { PrintService } from '../shared/print.service';

@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss']
})
export class OrderDialogComponent implements OnInit {

  defval = 'deliver';
  updateForm: FormGroup;
  details: any;
  checked: boolean

  constructor(
    public dialogRef: MatDialogRef<OrderDialogComponent>,
    private orderService: OrderService,
    private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data,
    public printService: PrintService) {
    this.details = data;
  }

  ngOnInit() {
    console.log("WEWEW", this.details)
    if (this.details.option === 'update') {
      this.createForm();
    }
  }

  get f() { return this.updateForm.controls; }

  createForm() {
    this.updateForm = this.formBuilder.group({
      status: [this.details.row.status, Validators.required],
      // remarks: [this.details.row.remarks, Validators.required],
      est_deliverydate: [this.details.row.est_deliverydate, Validators.required],
      or_no: [this.details.row.or_no, Validators.required],
      tracking_no: [this.details.row.tracking_no, Validators.required],
      customer_tin_no: [this.details.row.customer_tin_no],
      check_bank: [this.details.row.check_bank],
      check_no: [this.details.row.check_no],
      check_date: [this.details.row.check_date]
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.updateForm.invalid) {
      console.log('err', this.updateForm)
      return;
    }

    const newData = {
      order_id: this.details.row.order_no,
      status: this.f.status.value,
      // remarks: this.f.remarks.value,
      or_no: this.f.or_no.value,
      tracking_no: this.f.tracking_no.value,
      check_date: this.f.check_date.value,
      customer_tin_no: this.f.customer_tin_no.value,
      check_bank: this.f.check_bank.value,
      check_no: this.f.check_no.value,
      est_deliverydate: this.f.est_deliverydate.value,
    }

    this.orderService.updateStatus(newData)
      .pipe(first())
      .subscribe(
        data => {
          this.dialogRef.close(true)
        },
        error => {
          // this.alertService.error(error);
          // this.loading = false;
        });
  }

  printOR() {
    this.dialogRef.close(false)
    this.printService.printDocument(this.details.row.order_no)
  }

}
