import { Carousel } from '../../shared/carousel.config';
import { UserService } from '../shared/user.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import { UsersService } from '../../_admin-services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {


  signinForm: FormGroup;
  okFlg: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute) { }


  ngOnInit() {

    this.createForm();
  }

  createForm() {
    this.signinForm = this.formBuilder.group({
      email_address: [ '', Validators.required ]
    });
  }

  get f() { return this.signinForm.controls; }

  onSubmit() {
    if (this.signinForm.invalid) {
      alert('Please complete the signin form');
      return;
    }

    this.userService.forgotPassword(this.f.email_address.value).subscribe( res => {
      this.okFlg = true;
      alert('pls check you email address');
      this.signinForm.reset();
      // this.myform.reset();
      return;
    });
  }

}
