import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { Transaction, OrderTransaction } from '../shared/transaction.model';
import { Router } from '@angular/router';
import { UserService } from '../shared/user.service';
import { map, catchError } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { UserProvider } from '../shared/users.provider';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss']
})
export class TransactionListComponent implements OnInit {

  @ViewChild('editTemplate', { read: true, static: false }) sort: MatSort;
  transList: OrderTransaction[] = []
  dataSource: MatTableDataSource<OrderTransaction>;
  displayedColumns = ['id', 'date', 'order_no', 'summary', 'status', 'viewAction'];
  summaryList: any[] = []
  search_loading = false

  statMess = ['Waiting for proof of payment', 'Payment Confirmed', 'Ready for pick-up', 'Out for delivery',
    'Delivered', 'Return and exchange', 'Cancelled', 'Closed']

  constructor(private router: Router, private userService: UserService, private userProvider: UserProvider) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.transList);
    this.dataSource.sort = this.sort;
    this.getTrans()
  }

  getTrans() {
    let observables = new Array();
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.userService.getTransaction(currentUser.id).subscribe(
      res => {
        if (res) {
          this.transList = res.data.items[0].order_list
          this.dataSource.data = this.transList
        }
      }
    )
  }

  openView(row) {
    this.userProvider.transData = row
    this.router.navigate(['./transaction-view']);
  }

}
