import { Carousel } from '../../shared/carousel.config';
import { UserService } from '../shared/user.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-user-signin',
  templateUrl: './user-signin.component.html',
  styleUrls: ['./user-signin.component.scss']
})
export class UserSigninComponent implements OnInit {

  chipLog: string = 'primary'
  chipReg: string = 'none'
  signinForm: FormGroup
  leftSlide: Carousel
  rightSlide: Carousel
  imgsUrl: string[] = []

  constructor(
    private formBuilder: FormBuilder, private userService: UserService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
    if (this.userService.hasCurrentUser()) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'))
      if(currentUser.usertype_id == 1) {
        this.router.navigate(['']);
      }else{
        this.router.navigate(['./order-request']);
      }
    }
  }

  createForm() {
    this.signinForm = this.formBuilder.group({
      username: [ '', Validators.required ],
      password: [ '', Validators.required ]
    });
  }

  onSubmit(){
    if(this.signinForm.invalid){
      alert('Please complete the signin form')
      return
    }
    this.userService.signin(this.signinForm.getRawValue()).subscribe(
      res =>{
        if(res.status.error){
          alert("No user found")
          return
        }
        console.log('onSubmit', res.status.error)
        let url = this.router.url
        if(url === '/user/admin'){
          let tokenData: any = this.tokenData(res.data.token)
          if(tokenData.user_info.usertype_id != 2){
            alert("Your'e not allowed to signin here")
            return
          }
          this.userService.setUser(res.data.token)
          this.router.navigate(['./admin']);
        }else{
          this.userService.setUser(res.data.token)
          this.router.navigate(['']);
        }
      }
    )
  }

  tokenData(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

}
