import { UserService } from 'src/app/user-routes/shared/user.service';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-testimony-dialog',
  templateUrl: './testimony-dialog.component.html',
  styleUrls: ['./testimony-dialog.component.scss']
})
export class TestimonyDialogComponent implements OnInit {
  @ViewChild('file', { static: false }) file;
  public files: Set<File> = new Set();

  strUrl: any[] = [];
  rate: number = 0
  message: string
  rec: any
  isSaving: boolean = false

  constructor(@Inject(MAT_DIALOG_DATA) data, private service: UserService) {
    this.rec = data
   }

  ngOnInit() {}

  addFiles() {
    this.file.nativeElement.click();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key])
        let reader = new FileReader()
        reader.onload = (event: any) => {
          this.strUrl.push(event.target.result)
        }
        reader.readAsDataURL(files[key])
      }
    }
  }

  saveTestimony(){
    if(this.isSaving) return
    this.isSaving = true
    let body = {
      item_id: this.rec.item_id,
      user_id: this.rec.user_id,
      rate: this.rate,
      message: this.message,
      order_no: this.rec.order_no
    }
    this.service.uploadTestimony(body, this.files).subscribe(res => {
      console.log('saveTestimony', res)
      this.isSaving = false
    })
  }

}
