import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { UserService } from '../shared/user.service';
import { Router } from '@angular/router';
import { map, catchError, first } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as places from '../../ordering-routes/shared/cities.json'
import { UserDetail } from '../shared/transaction.model';
import { UsersService } from 'src/app/_admin-services';

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.scss']
})
export class UserSignupComponent implements OnInit {

  signupForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  place = places.default;
  mcit: any[] = [];
  mreg: any[] = [];
  userDetail: UserDetail

  imgFile: File
  isUpdate = false;

  constructor(private formBuilder: FormBuilder, private userService: UserService, private router: Router,
    private userSerive: UsersService) { }

  ngOnInit() {
    this.isUpdate = this.router.url.includes('update');
    if(this.isUpdate) this.setData()
    this.createForm();
  }

  createForm() {
    this.signupForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password2: ['', Validators.required],
      firstname: ['', Validators.required],
      middlename: ['', Validators.required],
      surename: ['', Validators.required],
      email_address: ['', [Validators.required, Validators.email]],
      contact_no: ['', Validators.required],
      age: ['', Validators.required],
      gender: ['', Validators.required],
      destination: [[], Validators.required],
      region: [[], Validators.required],
      city: [[], Validators.required],
      address: ['', Validators.required]
    }, { validator: this.checkPasswords })
  }

  setData(){
    let user = JSON.parse(localStorage.getItem('currentUser'))
    this.userService.getUser(user.id).subscribe(
      res => {
        this.userDetail = res.data.items[0]
        console.log('UserSignupComponent', this.userDetail)
        this.signupForm.controls['username'].setValue(this.userDetail.username)
        this.signupForm.controls['password'].setValue('******')
        this.signupForm.controls['password2'].setValue('******')
        this.signupForm.controls['firstname'].setValue(this.userDetail.firstname)
        this.signupForm.controls['middlename'].setValue(this.userDetail.middlename)
        this.signupForm.controls['surename'].setValue(this.userDetail.surename)
        this.signupForm.controls['email_address'].setValue(this.userDetail.email_address)
        this.signupForm.controls['contact_no'].setValue(this.userDetail.contact_no)
        this.signupForm.controls['age'].setValue(this.userDetail.age)
        this.signupForm.controls['gender'].setValue(+this.userDetail.gender)
        this.signupForm.controls['address'].setValue(this.userDetail.address)
        this.localUrl = this.userDetail.imgProfile[0].file
        let tmp: any = []
        for(let p of this.place){
          if(p.delivery_name === this.userDetail.destination) tmp = p
        }
        this.signupForm.controls['destination'].setValue(tmp)
        for(let r of tmp.region_list){
          if(r.region_name === this.userDetail.region) tmp = r
        }
        this.signupForm.controls['region'].setValue(tmp)
        for(let c of tmp.city_list){
          if(c.city_name === this.userDetail.city) tmp = c
        }
        this.signupForm.controls['city'].setValue(tmp)
      }
    )
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.password2.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  onSignUp() {
    if (this.signupForm.invalid) {
      alert('Please complete the registration form')
      return
    }
    let data = this.signupForm.getRawValue()
    delete data.password2
    data.usertype_id = 1
    data.has_file = true
    data.destination = data.destination.delivery_name
    data.city = data.city.city_name ? data.city.city_name : ''
    data.region = data.region.region_name

    console.log('onSignUp', data)
    if(this.isUpdate){
      this.update(data)
      return
    }

    this.userService.insertClient(data, this.imgFile).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            return

          case HttpEventType.Response:
            let res: any = event.body
            this.router.navigate(['/user']);
            return
        }
      }),
      catchError((err: HttpErrorResponse) => {
        console.log('HttpErrorResponse', err)
        return Observable.throw(err);
      })
    ).subscribe()
  }

  update(data) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.userService.clientUpdate(data, this.imgFile, currentUser.id)
    .pipe(first())
    .subscribe(
      data => {
        this.router.navigate(['/user-info']);
      },
      error => {
        alert('Plese try again')
      });
  }

  localUrl = "";
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
      this.imgFile = event.target.files[0];
      // this.isUpload = true
    }
  }

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidParent = !!(
      control
      && control.parent
      && control.parent.invalid
      && control.parent.dirty
      && control.parent.hasError('notSame'));
    return (invalidParent);
  }
}
