import { ApisService, saveItemURL, order, carouselUrl, carouselUrlAbout } from './../../shared/apis.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderingService {

  constructor(private apiService: ApisService) { }

  getAllItems(page: number, limit: number){
    return this.apiService.get(saveItemURL + '?page=' + page + '&limit=' + limit)
  }

  getItem(id: number){
    return this.apiService.get(saveItemURL + '/' + id)
  }

  saveOrder(data: any){
    return this.apiService.post(order, data)
  }

  getCarousel(){
    return this.apiService.get(carouselUrl)
  }

  getCarouselAbout(){
    return this.apiService.get(carouselUrlAbout)
  }

}
