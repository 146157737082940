import { first } from 'rxjs/operators';
import { SharedDataService } from '.././../../shared/shared-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Transaction } from 'src/app/user-routes/shared/transaction.model';
import { OrderRequest } from '../../shared/admin.model';
import { OrderDialogComponent } from '../../order-dialog/order-dialog.component';

import { UserModalComponent } from '../../items/lists/modals/user-modal/user-modal.component';

import { UsersService, ItemsService } from '../../../_admin-services';

import { FormControl } from '@angular/forms';

import * as $ from 'jquery';
import * as moment from 'moment';
import { UserFormComponent } from '../form/form.component';
import { NoResultComponent } from '../../items/lists/modals/no-result/no-result.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class UserListComponent implements OnInit {

  date = new FormControl(new Date());
  date_To = new FormControl('');
  date_From = new FormControl('');

  @ViewChild('editTemplate', { read: true, static: false }) sort: MatSort;
  reqList: OrderRequest[] = [];
  dataSource: MatTableDataSource<OrderRequest>;
  search_loading = false;

  displayedColumns = ['id', 'user_no', 'name', 'type', 'viewAction'];

  constructor(private sharedData: SharedDataService, public dialog: MatDialog, private service: UsersService) { }
  paginator = {
    page : 1,
    limit : '20',
    key : '_all',
    type : '0'
  };

  pageItem = {
    first         : 1,
    total         : 0,
    pages         : 0,
    has_next      : 0,
    has_previous  : 0,
    next_page     : 0,
    previous_page : 0,
    offset        : 0,
    current       : 0
  };

  isObjectEmpty (Obj: any) {
    for (const key in Obj) {
      if (Obj.hasOwnProperty(key)){
        return false;
      }
    }
    return true;
  }

  ngOnInit() {
    this.getData(this.paginator);
    this.dataSource = new MatTableDataSource(this.reqList);
    this.dataSource.sort = this.sort;
  }

  getData(pgn: any) {

    const str = $.param(pgn);
    this.service.getAll(str)
    .subscribe( data => {
      this.search_loading = false;
      const d: any = data;
      if (!this.isObjectEmpty(d.data.items)) {
        this.pageItem = d.data.pagination;
        this.reqList = d.data.items;
        console.log( this.reqList);
        this.dataSource.data = this.reqList;
      } else {
        this.openDialog();
      }
    });
  }

  selectLimit(value: any) {
    this.paginator.limit = value;
    this.getData(this.paginator);
  }

  selectType(event: any) {
    this.paginator.type = event;
  }
  pageAction (action: string) {
    switch (action) {
      case 'first' : {
        this.paginator.page = 1;
        break;
      }
      case 'prev' : {
        this.paginator.page = this.pageItem.previous_page;
        break;
      }
      case 'next' : {
        this.paginator.page = this.pageItem.next_page;
        break;
      }
      case 'last' : {
        this.paginator.page = this.pageItem.pages;
        break;
      }
    }

    this.getData(this.paginator);
  }

  applyFilter(key: string) {
    if (key) {
      this.paginator.page = 1;
      this.paginator.key = key;
      this.getData(this.paginator);
    } else {
      this.paginator.page = 1 ;
      this.paginator.key = '';
      this.getData(this.paginator);
    }
  }

  openView(row: any, option: string) {
    const dialogRef = this.dialog.open(UserModalComponent, {
      width: '650px', height: '700px',
      data: {
        option,
        row
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getData(this.paginator);
      }
    });
  }

  additem() {
    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '750px',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
      this.getData(this.paginator);
    });
  }

  edit(data) {
    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '750px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
      this.getData(this.paginator);
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NoResultComponent, {
      width: '250px',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  date_FromChange(value: any) {
    this.paginator['date_from'] = moment(new Date(value)).format('YYYY-MM-DD');
    console.log(this.paginator);
  }

  date_ToChange(value: any) {
    this.paginator['date_to'] = moment(new Date(value)).format('YYYY-MM-DD');
    console.log(this.paginator);
  }

  delete(id) {
    this.service.delete(id).subscribe(data => {
      const d: any = data;
      this.getData(this.paginator);
    });
  }
}
