import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderService } from 'src/app/_admin-services';
import { MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { OrderRequest } from '../../shared/admin.model';
import { ORHistory } from '../../shared/or-history';
import { ReturnExchangeDialogComponent } from '../return-exchange-dialog/return-exchange-dialog.component';

@Component({
  selector: 'app-return-exchange-form',
  templateUrl: './return-exchange-form.component.html',
  styleUrls: ['./return-exchange-form.component.scss']
})
export class ReturnExchangeFormComponent implements OnInit {

  @ViewChild('editTemplate', { read: true, static: false }) sort: MatSort;
  dataSource: MatTableDataSource<ORHistory>;
  orHistory: ORHistory[] = []
  displayedColumns = ['id', 'created_date', 'or_no', 'item_name', 'type', 'quantity', 'viewAction'];

  items: any[] = []
  type: number = 1
  selItem: any = null
  resp: any

  orStat: number = 0
  // 1 = Searched
  // 2 = Loading
  // 3 = Failed
  or: string = ""
  qty: number = null
  remark: string=""
  btnDisabled = false

  constructor(private service: OrderService, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.orHistory)
    this.dataSource.sort = this.sort
    this.getHistory()
  }

  getHistory(){
    this.service.getAllOR().subscribe(res => {
      this.orHistory = res.data.items
      this.dataSource.data = this.orHistory
    })
  }

  searchOR(str: string){
    this.resp = null
    if(str.length == 0){
      this.orStat = 0
      return
    }
    this.items = []
    this.selItem = null
    this.orStat = 2
    this.service.getOR(str).subscribe(res => {
      this.resp = res
      this.orStat = 1
      this.items = this.resp.data.items
    },err => {
      this.orStat = 3
    })
  }

  clsOR(){
    this.or=""
    this.orStat = 0
    this.items = []
    this.selItem = null
    this.qty = null
    this.resp = null
  }

  setRE(){
    if(this.orStat != 1){
      alert("Invalid OR number")
      return
    }
    if(this.selItem == null){
      alert("Select an item")
      return
    }
    if(this.qty == null || (this.qty <= 0 || this.qty > this.selItem.quantity)){
      alert("Invalid item quantity")
      return
    }
    if(this.remark===""){
      alert("Invalid item remark")
      return
    }

    let body={
      or_no: this.or,
      quantity: this.qty,
      remarks: this.remark,
      type: this.type,
      item_id: this.selItem.id
    }
    this.btnDisabled = true
    this.service.setRE(body).subscribe(res => {
      console.log(res)
      this.btnDisabled = false
      this.remark=""
      this.clsOR()
      this.getHistory()
    })
  }

  openView(or: string){
    this.dialog.open(ReturnExchangeDialogComponent, {
      width: '750px',
      data: or
    });
  }

}
