import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/user-routes/shared/user.service';
import { ActivatedRoute, RouterEvent, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header-page',
  templateUrl: './header-page.component.html',
  styleUrls: ['./header-page.component.scss']
})
export class HeaderPageComponent implements OnInit {

  showPopover: boolean = false
  popoverFocus: boolean = false

  currentUser: any
  public destroyed = new Subject<any>();

  constructor(public userService: UserService, private router: Router, public service: UserService) { }

  ngOnInit() {
    if (this.service.isTokenExpired()) {
      console.log('Token Exp')
      this.service.signout()
      this.router.navigate([''])
    }
    let mCart = JSON.parse(localStorage.getItem('mCart'))
    if(!mCart) sessionStorage.setItem('mCart', '[]')
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe((res) => {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    });
  }

  mouseover(){
    this.showPopover = true
  }

  mouseout(){
    setTimeout(() => {
      this.showPopover = this.popoverFocus
    }, 100);
  }

}
