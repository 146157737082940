import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../shared/shared-data.service';
import { UserService } from '../user-routes/shared/user.service';

@Component({
  selector: 'app-admin-routes',
  templateUrl: './admin-routes.component.html',
  styleUrls: ['./admin-routes.component.scss']
})
export class AdminRoutesComponent implements OnInit {

  drawerShow: boolean = false;
  drawerOpen: boolean = false;
  showSettingMenu = false;

  btnId = 1;

  constructor(public userService: UserService) {
  }

  ngOnInit() {
  }

  toggleSettingMenu() {
    this.showSettingMenu = !this.showSettingMenu;
  }

  drawerToggle() {
    this.drawerShow = !this.drawerShow
    this.drawerOpen = !this.drawerOpen
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.drawerOpen = true;
      this.drawerShow = true;
    }, 500);
  }

}
