// import { UserService } from './../shared/user.service';
import { UsersService } from '../../_admin-services';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ResetPasswordPrv } from '../shared/reserpassword.provider';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password-verify',
  templateUrl: './reset-password-verify.component.html',
  styleUrls: ['./reset-password-verify.component.scss']
})
export class ResetPasswordVerifyComponent implements OnInit {

  constructor(public router: Router,
    private route: ActivatedRoute,
    private api: UsersService,
    private resetPasswordData: ResetPasswordPrv) { }

  ngOnInit() {

    const token = this.route.snapshot.params['token'];
    this.api.passwordVerify(token)
    .pipe(
      retry(1),
      catchError(this.handleError),
    )
    .subscribe(res => {
      const d: any = res;
      this.resetPasswordData.data = d.data;
      this.resetPasswordData.data.pwd_url = token;
        this.router.navigate(['reset-password/edit']);
    });
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.statusText}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.statusText}`;
    }
    // this.router.navigate(['']);
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
