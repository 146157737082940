import { id } from '@swimlane/ngx-charts/release/utils';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsertypeGuard implements CanActivate {

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    if (currentUser != null) {
      if (currentUser.id != 1) {
        alert("You can't access this page")
        this.router.navigate([''])
      }
    }

    return this.canActivate(next, state);
  }

  constructor(public router: Router) { }

  canActivate( next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    console.log('canActivate', next.routeConfig.path)
    if (currentUser != null) {
      if(next.routeConfig.path === 'purchase-procedure'){
        let items = sessionStorage.getItem('mCart')
        if(items)
          if(JSON.parse(items).length == 0){
            alert('You have no items yet to purchase')
            this.router.navigate([''])
            return false
          }else return true
        else return false
      } else return true
    }else{
      alert('Please signin first')
      this.router.navigate([''])
      return false
    }
  }
}
