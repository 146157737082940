import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-return-exchange-dialog',
  templateUrl: './return-exchange-dialog.component.html',
  styleUrls: ['./return-exchange-dialog.component.scss']
})
export class ReturnExchangeDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
