import { Component, OnInit } from '@angular/core';
import { UserDetail } from '../shared/transaction.model';
import { UserService } from '../shared/user.service';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  userDetail: UserDetail
  isUpdate = false;
  public destroyed = new Subject<any>();
  user = JSON.parse(localStorage.getItem('currentUser'))

  constructor(public userService: UserService, private router: Router) { }

  ngOnInit() {
    this.isUpdate = this.router.url.includes('update');
    this.userService.getUser(this.user.id).subscribe(
      res => {
        this.userDetail = res.data.items[0]
      }
    )
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe((res) => {
      let tmp: any = res;
      this.isUpdate = tmp.urlAfterRedirects.includes('update');
      this.userService.getUser(this.user.id).subscribe(
        res => {
          this.userDetail = res.data.items[0]
        }
      )
    });
  }

  localUrl = "";
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
          this.localUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

}
