import { Component, OnInit } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { single } from './data';

import { ReportService } from '../../_admin-services';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  single: any[];

  view: any[] = [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Date';
  showYAxisLabel = true;
  yAxisLabel = 'Item count';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  constructor(private reportService: ReportService) { }

  ngOnInit() {
    console.log(single);
    // Object.assign(this, { single });
    this.reportService.get('').subscribe( res => {
      const d: any = res;
      this.single = d.data;
      // console.log(res);
    });
  }

  onSelect(event) {
    console.log(event);
  }

  getData() {

  }

}
