import { Component, OnInit } from '@angular/core';

import { OrderingService } from '../../ordering-routes/shared/ordering.service';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  title = 'ngSlick';
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    nextArrow: "<div class='nav-btn next-slide'></div>",
    prevArrow: "<div class='nav-btn prev-slide'></div>"
  };

  slides: any[] = [];

  constructor(private service: OrderingService) { }

  ngOnInit() {

    this.service.getCarouselAbout().subscribe( res => {
      const d: any = res.data;
      d.forEach(element => {
        this.slides.push({
          img: element.file
        });
      });
    });
  }

}
