import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  data: any
  private update: BehaviorSubject<any> = new BehaviorSubject(this.data);

  constructor() { }

  setData(data: any){
    this.update.next(data)
  }

  subData(): Observable<any>{
    return this.update
  }

}
