import { first } from 'rxjs/operators';
import { SharedDataService } from '.././../../shared/shared-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Transaction } from 'src/app/user-routes/shared/transaction.model';
import { OrderRequest } from '../../shared/admin.model';
import { OrderDialogComponent } from '../../order-dialog/order-dialog.component';

import { ItemCategService } from '../../../_admin-services';

import { FormControl } from '@angular/forms';

import * as $ from 'jquery';
import * as moment from 'moment';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { NoResultComponent } from '../../items/lists/modals/no-result/no-result.component';
import { ItemCategFormComponent } from '../item-categ-form/item-categ-form.component';

@Component({
  selector: 'app-item-categ-list',
  templateUrl: './item-categ-list.component.html',
  styleUrls: ['./item-categ-list.component.scss']
})
export class ItemCategListComponent implements OnInit {

  @ViewChild('editTemplate', { read: true, static: false }) sort: MatSort;

  reqList: OrderRequest[] = [];
  dataSource: MatTableDataSource<OrderRequest>;
  displayedColumns = ['id', 'name', 'description', 'viewAction'];
  search_loading = false;

  constructor(private sharedData: SharedDataService, public dialog: MatDialog, private itemCategService: ItemCategService,
    private router: Router) { }

  isObjectEmpty (Obj: any) {
    for (const key in Obj) {
      if (Obj.hasOwnProperty(key)){
        return false;
      }
    }
    return true;
  }

  ngOnInit() {
    this.getData();
    this.dataSource = new MatTableDataSource(this.reqList);
    this.dataSource.sort = this.sort;
  }

  getData() {

    this.itemCategService.getAll()
    .subscribe( data => {
      this.search_loading = false;
      const d: any = data;
      if (!this.isObjectEmpty(d.data.items)) {
        this.reqList = d.data.items;
        console.log( this.reqList);
        this.dataSource.data = this.reqList;
      } else {
        this.openDialog();
      }
    });
  }

  delete(id) {
    if (confirm('Are you sure you want to delete this item?')) {
      this.itemCategService.delete(id).subscribe(data => {
        const d: any = data;
        this.getData();
      });
    }
  }

  additem() {
    const dialogRef = this.dialog.open(ItemCategFormComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getData();
    });
  }

  edit(data) {
    const dialogRef = this.dialog.open(ItemCategFormComponent, {
      width: '350px',
      data : data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getData();
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NoResultComponent, {
      width: '250px',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }

}
