import { ApisService } from './../shared/apis.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTP_API_URL, getOrderID } from '../shared/apis.service';

@Injectable({ providedIn: 'root' })
export class OrderService {

  constructor(private http: HttpClient, private service: ApisService) { }

  getAll(data: any) {
    return this.http.get(`${HTTP_API_URL}order-record?${data}`);
  }

  updateStatus(data: any) {
    return this.http.post(`${HTTP_API_URL}order-update-status`, data);
  }

  getOrderTrans(id) {
    return this.http.get(getOrderID + id)
  }

  getOR(data: string){
    return this.http.get(`${HTTP_API_URL}return-and-exchange/check-or?or_no=${data}`);
  }

  setRE(data: any){
    return this.service.post(`${HTTP_API_URL}rtnexchange`, data)
  }

  getAllOR(){
    return this.service.get(`${HTTP_API_URL}rtnexchange`)
  }
}
