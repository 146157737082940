import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { PaymentFormService } from 'src/app/_admin-services/payment-form.service';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit {

  height = '600px'
  ind = 0
  total = 0
  currentUser: any
  formInfo: any

  imgs = ['../../../assets/images/form_cebuana.JPG', '../../../assets/images/form_lbc.JPG',
          '../../../assets/images/form_pnb.jpg', '../../../assets/images/form_bdo_cash.JPG',
          '../../../assets/images/form_bdo_check.JPG', '../../../assets/images/form_bpi.JPG']

  constructor(@Inject(MAT_DIALOG_DATA) data, private service: PaymentFormService) {
    this.ind = data.ind
    this.total = data.total
    if(data.ind == 2 || data.ind == 5){
      this.height = '950px'
    }

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
  }

  ngOnInit() {
    this.getForm();
  }

  getForm(){
    this.service.getForm().subscribe(res => {
      let tmp = res.data.items[0]
      this.formInfo = tmp
      console.log('PaymentDialogComponent', this.formInfo, this.ind)
    })
  }

}
