
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ItemCategService, ItemsService, UserTypeService } from '../../../_admin-services';
import { first } from 'rxjs/operators';
import { id } from '@swimlane/ngx-charts/release/utils';

import * as $ from 'jquery';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})

export class ItemsFormComponent implements OnInit {

  @ViewChild('file', { static: false }) file;
  public files: Set<File> = new Set();

  @ViewChild('inst_file', { static: false }) inst_file;
  public inst_files: Set<File> = new Set();

  mform: FormGroup;
  itemCategory = [];
  details: any;
  strUrl: any[] = [];
  inst_strUrl: any[] = [];
  currentImages: any[] = [];
  inst_currentImages: any[] = [];
  isView = false;
  isSubmit = false;
  itemSlug = '';

  constructor(
    public dialogRef: MatDialogRef<ItemsFormComponent>,
    private itemCategService: ItemCategService,
    private itemsService: ItemsService,
    private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data) {
    this.details = data;

    if(data) {
     if(data.action==='view') this.isView = true
      console.log('ItemsFormComponent', this.details.action)
    }
  }

  progress: any;
  canBeClosed = true;
  primaryButtonText = '';
  showCancelButton = true;
  uploading = false;
  uploadSuccessful = false;
  submitted = false;
  inst_uploading = false;
  inst_uploadSuccessful = false;

  ngOnInit() {
    if (this.details) {
      this.getItem(this.details.id);
    }

    this.form();
    this.getItemCateg();
    this.primaryButtonText = (this.details) ? 'Edit Item' : 'Add Item';

  }

  form() {
    console.log('form', this.details);
    this.mform = this.formBuilder.group({
      itemcateg_id: [(this.details) ? this.details.itemcateg_id : '', Validators.required],
      name: [(this.details) ? this.details.name : '', Validators.required],
      description: [(this.details) ? this.details.description : '', Validators.required],
      min_stock: [(this.details) ? this.details.min_stock : '', Validators.required],
      current_stock: [(this.details) ? this.details.current_stock : '', Validators.required],
      price: [(this.details) ? this.details.price : '', Validators.required],
      length: [(this.details) ? this.details.length : '', Validators.required],
      width: [(this.details) ? this.details.width : '', Validators.required],
      height: [(this.details) ? this.details.height : '', Validators.required],
      weight: [(this.details) ? this.details.weight : '', Validators.required],
      remarks: [(this.details) ? this.details.remarks : '', Validators.required],
      // item_slug: [(this.details) ? this.details.item_slug : '', Validators.required],
      shipping_fee_ncr: [(this.details) ? this.details.shipping_fee_ncr : '', Validators.required],
      shipping_fee_luz: [(this.details) ? this.details.shipping_fee_luz : '', Validators.required],
      shipping_fee_vis: [(this.details) ? this.details.shipping_fee_vis : '', Validators.required],
      shipping_fee_min: [(this.details) ? this.details.shipping_fee_min : '', Validators.required],
      has_file: [true]
    });
  }

  get f() { return this.mform.controls; }

  onFilesAdded() {
    console.log('SHiy')
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.strUrl.push(event.target.result);
        };
        reader.readAsDataURL(files[key]);
      }
    }
  }

  make_slug(event) {
    function s(d) {
      return d.replace(/\s+/g, '-').toLowerCase();
    }
    const name   = s(this.f.name.value);
    const length = s(this.f.length.value.toString());
    const width  = s(this.f.width.value.toString());
    const height = s(this.f.height.value.toString());
    const weight = s(this.f.weight.value.toString());
    this.itemSlug = s(name + 'x' + 'Lx' + length + 'Wx' + width + 'HTx' + height + 'WTx' + weight);
    // this.mform.setValue({
    //   item_slug : itemSlug
    // });
  }

  inst_onFilesAdded() {
    const inst_files: { [key: string]: File } = this.inst_file.nativeElement.files;
    for (let key in inst_files) {
      if (!isNaN(parseInt(key))) {
        this.inst_files.add(inst_files[key]);
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.inst_strUrl.push(event.target.result);
        };
        reader.readAsDataURL(inst_files[key]);
      }
    }

  }

  addFiles() {
    this.file.nativeElement.click();
  }

  inst_addFiles() {
    this.inst_file.nativeElement.click();
  }

  onSubmit() {

    if (this.strUrl.length === 0 && this.currentImages.length === 0) {
      alert('Images is empty');
      return;
    }

    if (this.inst_strUrl.length === 0 && this.inst_currentImages.length === 0) {
      alert('Instruction Images is empty');
      return;
    }

    if (this.isSubmit) { return; }
    console.log(this.mform)
    this.submitted = true;
    if (this.mform.invalid) {
      alert('Pls fillup all required files');
      return;
    }

    if (this.files.size === 0 && !this.details) {
      alert('Images is empty');
      return;
    }

    const details = {
      itemcateg_id: this.f.itemcateg_id.value,
      name: this.f.name.value,
      description: this.f.description.value,
      min_stock: this.f.min_stock.value,
      current_stock: this.f.current_stock.value,
      price: this.f.price.value,
      length: this.f.length.value,
      width: this.f.width.value,
      height: this.f.height.value,
      weight: this.f.weight.value,
      remarks: this.f.remarks.value,
      shipping_fee_ncr: this.f.shipping_fee_ncr.value,
      shipping_fee_luz: this.f.shipping_fee_luz.value,
      shipping_fee_vis: this.f.shipping_fee_vis.value,
      shipping_fee_min: this.f.shipping_fee_min.value,
      item_slug: this.itemSlug,
      has_file: true
    };

    this.isSubmit = true;

    if (this.details) {

      let Ids = this.currentImages.map(function (data) {
        return data.id;
      });

      let str = Ids.join(', ');
      details['new_id_image'] = str;

      let inst_Ids = this.inst_currentImages.map(function (data) {
        return data.id;
      });

      let inst_str = inst_Ids.join(', ');
      details['inst_new_id_image'] = inst_str;

      this.editItem(details);

    } else {
      this.createItem(details);
    }
  }

  editItem(details) {
    this.itemsService.update(details, this.files, this.inst_files, this.details.id)
      .pipe(first())
      .subscribe(
        data => {
          this.dialogRef.close();
        },
        error => {

        }
      );
  }

  getItem(id: any) {
    this.itemsService.get(id)
      .subscribe(data => {
        const d: any = data;
        this.details = d.data;
        this.currentImages = this.details.images;
        this.inst_currentImages = this.details.instruction_images;
      });
  }

  createItem(details) {
    this.itemsService.create(details, this.files, this.inst_files)
      .pipe(first())
      .subscribe(
        data => {
          this.submitted = false;
          this.dialogRef.close();
        },
        error => {
          this.submitted = false;
          console.log();
        });
  }

  getItemCateg() {
    this.itemCategService.getAll()
      .subscribe(data => {
        const d: any = data;
        if (!this.isObjectEmpty(d.data.items)) {
          this.itemCategory = d.data.items;
        }
      });
  }

  removeCurrentImg(i) {
    this.currentImages.splice(i, 1);
  }

  inst_removeCurrentImg(i) {
    this.inst_currentImages.splice(i, 1);
  }

  removeImg(i) {
    this.files.delete(i);
    this.strUrl.splice(i, 1);
  }

  inst_removeImg(i) {
    this.inst_files.delete(i);
    this.inst_strUrl.splice(i, 1);
  }

  isObjectEmpty(Obj: any) {
    for (const key in Obj) {
      if (Obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

}
