import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import 'rxjs/add/observable/throw';
// import { throwError as _throw } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  enableLog: boolean;

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  public handleError<T>(operation = 'operation', result?: T) {
    return (response: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      this.error(response);

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${response.message}`);

      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return of(response.error as T);
    };
  }

  public log(message: any) { if (this.enableLog) console.log(message); }
  public error(message: string) { if (this.enableLog) console.error(message); }

  constructor(public http: HttpClient) { this.enableLog = true }

  get(url: string) {
    return this.http.get<any>(url).pipe(
      tap(_ => this.log('fetched data by id')),
      catchError(this.handleError<any>(`get error`))
    )
  }

  post(url: string, body: any) {
    return this.http.post<any>(url, body).pipe(
      tap((result: any) => this.log(`post data from ${url}`)),
      catchError(this.handleError<any>('post error'))
    )
  }

  bodyPut(url: string, body: any){
    return this.http.put<any>(url, body).pipe(
      tap((result: any) => this.log(`post data from ${url}`)),
      catchError(this.handleError<any>('post error'))
    )
  }

  put(url: string, body: any) {

    return this.http.put(url, body);
  }

  uploadImage(url: string, formData: FormData) {

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });

    return this.http.request(req);

  }

  insertClient(url: string, formData: FormData) {

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });

    return this.http.request(req);

  }
}

// export const HTTP_API_URL = "http://localhost:8000/";
export const HTTP_API_URL = 'http://139.162.52.210:9000/';

export const signinURL = HTTP_API_URL + 'user/login';
export const saveItemURL = HTTP_API_URL + 'item';
export const addClient = HTTP_API_URL + 'client';
export const userUrl = HTTP_API_URL + 'user';
export const order = HTTP_API_URL + 'order-record';
export const custOrder = order + '/customer?customer_id=';
export const getOrderID = order + "/transaction?order_no=";
export const proofPaymentURL = HTTP_API_URL + "proof-of-payment/";
export const remarksUrl = HTTP_API_URL + 'testimony';
export const resetPass = HTTP_API_URL + 'user/reset-password';
export const contactUs = HTTP_API_URL + 'contact-us';
export const guides = HTTP_API_URL + 'setting/guide';
export const saveFormInfo = HTTP_API_URL + 'payment-form-info?id=1'
export const carouselUrl = HTTP_API_URL + 'setting/image-upload?file_type=home-page'
export const carouselUrlAbout = HTTP_API_URL + 'setting/image-upload?file_type=about'
