import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MaterialsModule } from './shared/materials.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { UserSigninComponent } from './user-routes/user-signin/user-signin.component';
import { LandingPageComponent } from './ordering-routes/landing-page/landing-page.component';
import { HeaderPageComponent } from './ordering-routes/header-page/header-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './shared/token.interceptor';
import { OrderRequestComponent } from './admin-routes/order-request/order-request.component';
import { SigninRouterComponent } from './user-routes/signin-router/signin-router.component';
import { UserSignupComponent } from './user-routes/user-signup/user-signup.component';
import { ItemDetailsComponent } from './ordering-routes/item-details/item-details.component';
import { ShoppingCartComponent } from './ordering-routes/shopping-cart/shopping-cart.component';
import { PurchaseProcComponent } from './ordering-routes/purchase-proc/purchase-proc.component';
import { TransactionListComponent } from './user-routes/transaction-list/transaction-list.component';
import { TransactioViewComponent } from './user-routes/transactio-view/transactio-view.component';
import { UserInfoComponent } from './user-routes/user-info/user-info.component';
import { AdminRoutesComponent } from './admin-routes/admin-routes.component';
import { OrderDialogComponent } from './admin-routes/order-dialog/order-dialog.component';
import { OrderID } from './ordering-routes/shared/ordering.prov';
import { AnalyticsComponent } from './admin-routes/analytics/analytics.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { UserProvider } from './user-routes/shared/users.provider';
import { ResetPasswordPrv } from './user-routes/shared/reserpassword.provider';
import { TransactionComponent } from './admin-routes/transaction/transaction.component';
import { MomentModule } from 'ngx-moment';
import { NoResultComponent } from './admin-routes/items/lists/modals/no-result/no-result.component';
import { UserListComponent } from './admin-routes/users/list/list.component';
import { UserFormComponent } from './admin-routes/users/form/form.component';
import { ItemsListsComponent } from './admin-routes/items/lists/lists.component';
import { ItemsFormComponent } from './admin-routes/items/form/form.component';
import { PrintOrComponent } from './admin-routes/print-or/print-or.component';
import { UserModalComponent } from './admin-routes/items/lists/modals/user-modal/user-modal.component';
import { ItemModalComponent } from './admin-routes/items/lists/modals/item-modal/item-modal.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AboutUsComponent } from './static-pages/about-us/about-us.component';
import { ContactUsComponent } from './static-pages/contact-us/contact-us.component';
import { AgmCoreModule } from '@agm/core';
import { ItemCategListComponent } from './admin-routes/item-category/item-categ-list/item-categ-list.component';
import { ItemCategFormComponent } from './admin-routes/item-category/item-categ-form/item-categ-form.component';
import { RetexPolicyComponent } from './static-pages/retex-policy/retex-policy.component';
import { PrivacyPolicyComponent } from './static-pages/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './static-pages/terms-condition/terms-condition.component';
import { TermsDialogComponent } from './ordering-routes/terms-dialog/terms-dialog.component';
import { PaymentDialogComponent } from './ordering-routes/payment-dialog/payment-dialog.component';
import { ResetPasswordVerifyComponent } from './user-routes/reset-password-verify/reset-password-verify.component';
import { ForgotPasswordComponent } from './user-routes/forgot-password/forgot-password.component';
import { ForgotPasswordResetComponent } from './user-routes/forgot-password-reset/forgot-password-reset.component';
import { PaymentFormComponent } from './admin-routes/payment-form/payment-form.component';
import { AboutComponent } from './admin-routes/about/about.component';
import { GuideComponent } from './admin-routes/guide/guide.component';
import { AdminLandingPageComponent } from './admin-routes/admin-landing-page/admin-landing-page.component';
import { GuideFormComponent } from './admin-routes/guide/guide-form/guide-form.component';
import { ReturnExchangeFormComponent } from './admin-routes/return-exchange/return-exchange-form/return-exchange-form.component';
import { ReturnExchangeDialogComponent } from './admin-routes/return-exchange/return-exchange-dialog/return-exchange-dialog.component';
import { SettingImgModalComponent } from './admin-routes/setting-img-modal/setting-img-modal.component';
import { SettingImgModalAddComponent } from './admin-routes/setting-img-modal-add/setting-img-modal-add.component';
import { TestimonyDialogComponent } from './user-routes/testimony-dialog/testimony-dialog.component';
import { ClientGuideComponent } from './static-pages/client-guide/client-guide.component';

@NgModule({
  declarations: [
    AppComponent,
    UserSigninComponent,
    LandingPageComponent,
    HeaderPageComponent,
    OrderRequestComponent,
    SigninRouterComponent,
    UserSignupComponent,
    ItemDetailsComponent,
    ShoppingCartComponent,
    PurchaseProcComponent,
    TransactionListComponent,
    TransactioViewComponent,
    UserInfoComponent,
    AdminRoutesComponent,
    OrderDialogComponent,
    AnalyticsComponent,
    TransactionComponent,
    NoResultComponent,
    UserListComponent,
    UserFormComponent,
    ItemsListsComponent,
    PrintOrComponent,
    UserModalComponent,
    ItemModalComponent,
    ItemsFormComponent,
    AboutUsComponent,
    ContactUsComponent,
    ItemCategListComponent,
    ItemCategFormComponent,
    RetexPolicyComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    TermsDialogComponent,
    PaymentDialogComponent,
    ResetPasswordVerifyComponent,
    ForgotPasswordComponent,
    ForgotPasswordResetComponent,
    PaymentFormComponent,
    AboutComponent,
    GuideComponent,
    AdminLandingPageComponent,
    GuideFormComponent,
    ReturnExchangeFormComponent,
    ReturnExchangeDialogComponent,
    SettingImgModalComponent,
    SettingImgModalAddComponent,
    TestimonyDialogComponent,
    ClientGuideComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxChartsModule,
    NgxMaterialTimepickerModule,
    MomentModule,
    FlexLayoutModule,
    SlickCarouselModule,
    MDBBootstrapModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAAgkRiT1_1LmwsfoMBLod6OKdmJs-TAvw'
    }),
    NgbModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    OrderID,
    UserProvider,
    ResetPasswordPrv
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    PaymentDialogComponent,
    TermsDialogComponent,
    OrderDialogComponent,
    NoResultComponent,
    UserModalComponent,
    ItemModalComponent,
    ItemsFormComponent,
    ItemCategFormComponent,
    UserFormComponent,
    GuideFormComponent,
    SettingImgModalComponent,
    SettingImgModalAddComponent,
    ReturnExchangeDialogComponent,
    TestimonyDialogComponent
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
