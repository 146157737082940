
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSort, MatTableDataSource, MatDialog } from '@angular/material';

import { ItemCategService, ItemsService, UserTypeService, SettingService } from '../../_admin-services';

import { SettingImgModalComponent } from '../setting-img-modal/setting-img-modal.component';

import { first } from 'rxjs/operators';
import { id } from '@swimlane/ngx-charts/release/utils';

import * as $ from 'jquery';
import { forkJoin } from 'rxjs';
import { SettingImgModalAddComponent } from '../setting-img-modal-add/setting-img-modal-add.component';

@Component({
  selector: 'app-admin-landing-page',
  templateUrl: './admin-landing-page.component.html',
  styleUrls: ['./admin-landing-page.component.scss']
})
export class AdminLandingPageComponent implements OnInit {

  @ViewChild('file', { static: false }) file;
  public files: Set<File> = new Set();
  mform: FormGroup;
  itemCategory = [];
  description: any[] = [];
  strUrl: any[] = [];
  currentImages: any[] = [];
  isView = false;
  uploading = false;
  isSubmit = false;

  uploadSuccessful = false;
  submitted = false;

  constructor(
    private itemCategService: ItemCategService,
    private itemsService: ItemsService,
    private settingService: SettingService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog) {

  }

  ngOnInit() {
    this.getImages();
  }

  addFiles() {
    // this.file.nativeElement.click();
    const dialogRef = this.dialog.open(SettingImgModalAddComponent, {
      width: '850px',
      height: '90%',
      data: 'home-page'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getImages();
    });

  }

  getImages() {
    this.settingService.getUploadedFiles('home-page')
    .subscribe(data => {
      const d: any = data;
      console.log(d.data);
      d.data.forEach(val => {
        this.description.push(val.description);
      });
      this.currentImages = d.data;
    });
  }

  edit(data) {
    // console.log(data);
    const dialogRef = this.dialog.open(SettingImgModalComponent, {
      width: '650px',
      data : data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.getImages();
    });
  }

  removeImg(img) {
    if (confirm('Are you sure you want to delete this image?')) {
      this.settingService.deleteImage(img.id).subscribe(data => {
        const d: any = data;
        this.getImages();
      });
    }
  }

  isObjectEmpty(Obj: any) {
    for (const key in Obj) {
      if (Obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

}
