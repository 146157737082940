import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-signin-router',
  templateUrl: './signin-router.component.html',
  styleUrls: ['./signin-router.component.scss']
})
export class SigninRouterComponent implements OnInit {

  pos = 1;
  url = '';
  isResetPass: boolean;
  public destroyed = new Subject<any>();

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.url = this.router.url
    if(this.url === '/user/signin') this.pos = 1
    else if(this.url === '/user/admin') this.pos = 1
    else if(this.url === '/user/signup') this.pos = 2
    else if(this.url === '/user/forgot-password') this.pos = 3

    this.isResetPass = this.url.includes('reset-password-verify');
    this.isResetPass = this.url.includes('reset-password');

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe((res) => {
      let tmp: any = res;
      this.isResetPass = tmp.urlAfterRedirects.includes('reset-password-verify');
    });

    // this.router.events.subscribe(val => {
    //   let tmp = val instanceof NavigationEnd
    //   this.isResetPass = this.url.includes('reset-password-verify')
    //   console.log('SigninRouterComponent', tmp)
    // })
  }

  reroute(n: number){
    if(n == 1) {
      if(n == this.pos) return
      else if(this.router.url === '/user/admin'){
        this.router.navigate(['./admin'], { relativeTo: this.route });
        return
      }
      this.router.navigate(['./signin'], { relativeTo: this.route });
    }else if(n == 2){
      if(n == this.pos) return
      this.router.navigate(['./signup'], { relativeTo: this.route });
    } else if (n == 3) {
      if(n == this.pos) return
      this.router.navigate(['./forgot-password'], { relativeTo: this.route });
    }
    this.pos = n
    console.log( this.pos)
  }

}
