import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  constructor(private router: Router, private route: ActivatedRoute) { }

  printDocument(no) {
    this.isPrinting = true;
    this.router.navigate(['./print-or', no])
  }

  onDataReady() {
    setTimeout(() => {

      window.print();
      this.isPrinting = false;
      this.router.navigate(['./admin']);
    });
  }
}
