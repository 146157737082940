import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTP_API_URL, getOrderID } from '../shared/apis.service';

@Injectable({ providedIn: 'root' })
export class ReportService {

  constructor(private http: HttpClient) { }

  get(data: any) {
    return this.http.get(`${HTTP_API_URL}report-prod-count?type=1&where_to=2018-01-01&where_from=2020-03-05`);
  }
}
