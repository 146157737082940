import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTP_API_URL } from '../shared/apis.service';

@Injectable({ providedIn: 'root' })
export class UsersService {

  constructor(private http: HttpClient) {}

  getAll(data: any) {
    return this.http.get(`${HTTP_API_URL}user?${data}`);
  }

  get(id) {
    return this.http.get(`${HTTP_API_URL}user/${id}`);
  }

  delete(id: any) {
    return this.http.delete(`${HTTP_API_URL}user?id=${id}`);
  }

  update(body, files: Set<File>, id) {

    const formData: FormData = new FormData();

    formData.append('usertype_id', body.usertype_id);
    formData.append('username', body.username);
    formData.append('email_address', body.email_address);
    // formData.append('password', body.password);
    formData.append('firstname', body.firstname);
    formData.append('middlename', body.middlename);
    formData.append('surename', body.surename);
    formData.append('contact_no', body.contact_no);
    formData.append('age', body.age);
    formData.append('gender', body.gender);
    formData.append('address', body.address);
    formData.append('has_file', body.has_file);

    let counter = 0;
    files.forEach(file => {
      counter ++;
      formData.append(`img_profile`, file);
    });

    body.file_count = counter;

    formData.append('file_count', body.file_count);

    return this.http.put(`${HTTP_API_URL}user?id=${id}`, formData );

  }

  create(body, files: Set<File>)  {

    const formData: FormData = new FormData();

    formData.append('usertype_id', body.usertype_id);
    formData.append('username', body.username);
    formData.append('email_address', body.email_address);
    formData.append('password', body.password);
    formData.append('firstname', body.firstname);
    formData.append('middlename', body.middlename);
    formData.append('surename', body.surename);
    formData.append('contact_no', body.contact_no);
    formData.append('age', body.age);
    formData.append('gender', body.gender);
    formData.append('address', body.address);
    formData.append('has_file', body.has_file);

    let counter = 0;
    files.forEach(file => {
      counter ++;
      formData.append(`img_profile`, file);
    });

    body.file_count = counter;

    formData.append('file_count', body.file_count);

    return this.http.post(`${HTTP_API_URL}user`, formData );

  }

  forgotPassword(email_address: string) {
    return this.http.post(`${HTTP_API_URL}user/reset-password`, {email_address : email_address });
  }

  passwordVerify(token: string) {
    return this.http.get(`${HTTP_API_URL}user/reset-password-verify/${token}`);
  }

  passwordReset(data: any) {
    return this.http.put(`${HTTP_API_URL}user/reset-password`, data);
  }
}
