import { ApisService, contactUs } from './../../shared/apis.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  form: FormGroup

  title: string = 'My first AGM project';
  lat: number = 14.576059;
  lng: number = 121.042404;
  public zoom = 15;
  isSending: boolean = false

  constructor(private formBuilder: FormBuilder, private api: ApisService) { }

  ngOnInit() {
    this.createForm()
  }

  createForm() {
    this.form = this.formBuilder.group({
      fullname: ['', Validators.required],
      company_name: ['', Validators.required],
      contact_number: ['', Validators.required],
      email_address: ['', Validators.required],
      subject: ['', Validators.required]
    })
  }

  setMessage(){
    if (this.form.invalid) {
      alert('Please complete the registration form')
      return
    }
    if(this.isSending) return
    this.isSending = true
    let body = this.form.getRawValue()
    console.log('ContactUsComponent', body)
    this.api.post(contactUs, body).subscribe(res => {
      console.log('ContactUsComponent', res)
      this.isSending = false
      this.form.controls['fullname'].setValue('')
      this.form.controls['company_name'].setValue('')
      this.form.controls['contact_number'].setValue('')
      this.form.controls['email_address'].setValue('')
      this.form.controls['subject'].setValue('')
      alert('Email Sent!')
    })
  }

}
