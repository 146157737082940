
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ItemCategService, ItemsService, SettingService } from '../../../_admin-services';
import { first } from 'rxjs/operators';
import { id } from '@swimlane/ngx-charts/release/utils';

import * as $ from 'jquery';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-guide-form',
  templateUrl: './guide-form.component.html',
  styleUrls: ['./guide-form.component.scss']
})
export class GuideFormComponent implements OnInit {

  mform: FormGroup;

  submitted = false;
  details: any;

  constructor(
    public dialogRef: MatDialogRef<GuideFormComponent>,
    private settingService: SettingService,
    private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data) {
      this.details = data;
  }

  ngOnInit() {
    console.log(this.details)
    this.mform = this.formBuilder.group({
      title: [(this.details) ? this.details.title : '', Validators.required],
      description: [(this.details) ? this.details.description : '']
    });
  }

  get f() { return this.mform.controls; }

  onSubmit() {

    if (this.mform.invalid) {
      alert('Pls fillup all required files');
      return;
    }

    const data = {
      title: this.f.title.value,
      description: this.f.description.value
    };

    if (this.details) {
      this.edit(data);
    } else {
      this.create(data);
    }
  }

  edit(details) {
    this.settingService.updateGuide(details, this.details.id)
    .pipe(first())
    .subscribe(
      data => {
        this.submitted = false;
        this.dialogRef.close();
      },
      error => {
        this.submitted = false;
        console.log();
      });
  }

  create(details) {
    this.settingService.createGuide(details)
    .pipe(first())
    .subscribe(
      data => {
        this.submitted = false;
        this.dialogRef.close();
      },
      error => {
        this.submitted = false;
        console.log();
      });
  }

}
