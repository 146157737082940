import { Component, OnInit } from '@angular/core';
import { Carousel } from 'src/app/shared/carousel.config';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderingService } from '../shared/ordering.service';
import { OrderItem } from '../shared/ordering.model';
import { OrderID } from '../shared/ordering.prov';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  rightSlide: Carousel
  imgsUrl: string[] = []
  items: OrderItem[] = []
  canCarClick: boolean = true
  carPos = 1
  cItems: any[] = []
  cItems2 = [
    {
      img: '../../../assets/images/product1.jpg',
      left: -25
    },
    {
      img: '../../../assets/images/product2.jpg',
      left: 25
    },
    {
      img: '../../../assets/images/product3.jpg',
      left: 75
    },
    {
      img: '../../../assets/images/product4.jpg',
      left: 125
    }
  ]
  timer: Subscription

  constructor(private router: Router, private service: OrderingService, private orderID: OrderID) { }

  ngOnInit() {
    this.service.getAllItems(1, 50).subscribe(
      res => {
        if(!res.data) return
        this.items = res.data.items
      }
    )
    this.service.getCarousel().subscribe(res => {
      console.log('ngOnInit', res)
      let start = res.data.length <= 2 ? 25 : -75
      for(let itms of res.data){
        this.cItems.push({
          img: itms.file,
          left: start
        })
        start = start + 50
      }
    })
    setInterval(() => {
      this.carClick(-50)
    },3000)
  }

  openItem(row) {
    // return(console.log(row.item_slug))
    // this.orderID.id = id
    this.router.navigate(['./item-info/' + row.item_slug]);
  }

  getDec(dec){
    let tmp = dec.toString().split('.')
    return (tmp[1].charAt(0) * 10) + '%'
  }

  async carClick(num: number){
    if(!this.canCarClick || this.cItems.length==1) return
    this.canCarClick = false

    if(num==50){ // if previous
      let tmp = this.cItems[this.cItems.length-1]
      let start = this.cItems.length <= 2 ? -25 : -75
      tmp.left = start
      this.cItems.splice(0, 0, tmp)
      this.cItems.splice(this.cItems.length-1, 1)
    }

    await this.delay(50);
    for(let itms of this.cItems) itms.left += num
    await this.delay(850);

    if(num==-50){ // if next
      let tmp = this.cItems[0]
      tmp.left = tmp.left + (50 * this.cItems.length)
      this.cItems.splice(0,1)
      await this.delay(50);
      this.cItems.push(tmp)
    }
    this.canCarClick = true
  }

  delay(ms: number){
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
