import { first } from 'rxjs/operators';
import { SharedDataService } from './../../shared/shared-data.service';
import { Component, OnInit, ViewChild, ContentChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Transaction } from 'src/app/user-routes/shared/transaction.model';
import { OrderRequest } from '../shared/admin.model';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { OrderService } from '../../_admin-services';
import { NoResultComponent } from '../items/lists/modals/no-result/no-result.component';
import * as $ from 'jquery';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-request',
  templateUrl: './order-request.component.html',
  styleUrls: ['./order-request.component.scss']
})
export class OrderRequestComponent implements OnInit {

  @ViewChild('editTemplate', { read: true, static: false }) sort: MatSort;
  reqList: OrderRequest[] = []
  dataSource: MatTableDataSource<OrderRequest>;
  displayedColumns = ['id', 'name', 'item', 'service', 'status', 'viewAction', 'viewUpdate'];

  constructor(private sharedData: SharedDataService, public dialog: MatDialog, private orderService: OrderService,
    private router: Router, private route: ActivatedRoute) { }

  samp: string;
  search_loading = false;
  paginator = {
    page: 1,
    limit: '20',
    key: '_all',
    module: 'request',
    type: '0'
  }

  pageItem = {
    first: 1,
    total: 0,
    pages: 0,
    has_next: 0,
    has_previous: 0,
    next_page: 0,
    previous_page: 0,
    offset: 0,
    current: 0
  }

  isObjectEmpty(Obj) {
    for (const key in Obj) {
      if (Obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NoResultComponent, {
      width: '250px',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }


  ngOnInit() {
    this.search_loading = true;
    this.orderPagination(this.paginator);
    this.dataSource = new MatTableDataSource(this.reqList);
    this.dataSource.sort = this.sort;
  }

  orderPagination(pgn: any) {
    const str = $.param(pgn);
    this.orderService.getAll(str)
      .subscribe(data => {
        this.search_loading = false;
        const d: any = data;
        if (!this.isObjectEmpty(d.data.items)) {
          this.pageItem = d.data.pagination;
          this.reqList = d.data.items;
          this.dataSource.data = this.reqList;
        } else {
          this.openDialog();
        }
      });
  }

  selectLimit(value: any) {
    this.paginator.limit = value;
    this.orderPagination(this.paginator);
  }

  selectType(event: any) {
    this.paginator.type = event;
  }

  pageAction(action: string) {
    switch (action) {
      case 'first': {
        this.paginator.page = 1;
        break;
      }
      case 'prev': {
        this.paginator.page = this.pageItem.previous_page;
        break;
      }
      case 'next': {
        this.paginator.page = this.pageItem.next_page;
        break;
      }
      case 'last': {
        this.paginator.page = this.pageItem.pages;
        break;
      }
    }

    this.orderPagination(this.paginator);
  }

  applyFilter(key: string) {
    if (key) {
      this.paginator.page = 1;
      this.paginator.key = key;
      this.orderPagination(this.paginator);
    } else {
      this.paginator.page = 1;
      this.paginator.key = '';
      this.orderPagination(this.paginator);
    }
  }

  openView(row: any, option: string) {
    const dialogRef = this.dialog.open(OrderDialogComponent, {
      width: '650px', height: '700px',
      data: {
        option,
        row
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.orderPagination(this.paginator);
      }
    });
  }

}
