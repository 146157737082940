import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// import { OrderService } from '../../_admin-services';
import { first } from 'rxjs/operators';
import { id } from '@swimlane/ngx-charts/release/utils';

@Component({
  selector: 'app-item-modal',
  templateUrl: './item-modal.component.html',
  styleUrls: ['./item-modal.component.scss']
})
export class ItemModalComponent implements OnInit {
  details: any;

  constructor(
    public dialogRef: MatDialogRef<ItemModalComponent>,
    // private orderService: OrderService,
    private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data) {
    this.details = data;
  }
  ngOnInit() {
    console.log('ItemModalComponent', this.details)
  }

}
