import { guides } from './../../shared/apis.service';
import { Component, OnInit } from '@angular/core';

import { ItemCategService, SettingService } from '../../_admin-services';
// import { OrderingService } from '';


@Component({
  selector: 'app-client-guide',
  templateUrl: './client-guide.component.html',
  styleUrls: ['./client-guide.component.scss']
})
export class ClientGuideComponent implements OnInit {

  constructor(private settingService: SettingService) { }

  guides: any[] = [];

  ngOnInit() {
    this.getData();
  }

  getData() {

    this.settingService.getAllGuide()
    .subscribe( data => {
      const d: any = data;
      this.guides = d.data.items;
      console.log(this.guides)
    });
  }
}
