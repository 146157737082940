import { Component, OnInit } from '@angular/core';
import { Carousel } from 'src/app/shared/carousel.config';
import { OrderID } from '../shared/ordering.prov';
import { OrderingService } from '../shared/ordering.service';
import { OrderItem } from '../shared/ordering.model';
import { Cookie } from 'ng2-cookies';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit {

  slide: Carousel
  imgsUrl: string[] = []
  img: string;
  defval: string = 'deliver'
  item: any
  items: any[] = []
  ifOnCart: boolean = false
  qnty: number = 1
  item_imgs: any[] = []

  constructor(private orderID: OrderID, private service: OrderingService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.slide = new Carousel;
    const item_slug = this.route.snapshot.params['item_slug'];

    this.service.getItem(item_slug).subscribe(
      res => {
        this.item = res.data;
        this.item_imgs = res.data.images;
        for (let img of this.item.images) {
          this.imgsUrl.push(img);
        }
        this.slide.setLimit(this.imgsUrl.length);
      }
    )

    let data = sessionStorage.getItem('mCart');

    if (data) {
      this.items = JSON.parse(data)
      for (let itm of this.items) {
        if (itm.item.id == this.orderID.id) {
          this.ifOnCart = true
          this.qnty = itm.qty
          this.defval = itm.package
        }
      }
    }
  }

  saveToCart(qty) {
    if (this.ifOnCart) {
      for (let itm of this.items) {
        if (itm.item.id == this.orderID.id) {
          itm.qty = this.qnty
          itm.package = this.defval
        }
      }
      sessionStorage.setItem('mCart', JSON.stringify(this.items))
      // Cookie.set('mCart', JSON.stringify(this.items), 0.0034);
      return
    }
    this.items.push({
      item: this.item,
      qty: qty,
      package: this.defval
    })
    sessionStorage.setItem('mCart', JSON.stringify(this.items))
    // Cookie.set('mCart', JSON.stringify(this.items), 0.0034);
  }

  getDec(dec) {
    let tmp = dec.toString().split('.')
    return (tmp[1].charAt(0) * 10) + '%'
  }

}
