
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ItemCategService, ItemsService } from '../../../_admin-services';
import { first } from 'rxjs/operators';
import { id } from '@swimlane/ngx-charts/release/utils';

import * as $ from 'jquery';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-item-categ-form',
  templateUrl: './item-categ-form.component.html',
  styleUrls: ['./item-categ-form.component.scss']
})
export class ItemCategFormComponent implements OnInit {

  mform: FormGroup;

  submitted = false;
  details: any;

  constructor(
    public dialogRef: MatDialogRef<ItemCategFormComponent>,
    private itemCategService: ItemCategService,
    private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data) {
      this.details = data;
  }


  ngOnInit() {
    console.log(this.details)
    this.mform = this.formBuilder.group({
      name: [(this.details) ? this.details.name : '', Validators.required],
      description: [(this.details) ? this.details.description : '']
    });
  }

  get f() { return this.mform.controls; }

  onSubmit() {

    if (this.mform.invalid) {
      alert('Pls fillup all required files');
      return;
    }

    const data = {
      name: this.f.name.value,
      description: this.f.description.value
    };

    if(this.details) {
      this.edit(data);
    } else {
      this.create(data);
    }
  }

  edit(details) {
    this.itemCategService.update(details, this.details.id)
    .pipe(first())
    .subscribe(
      data => {
        this.submitted = false;
        this.dialogRef.close();
      },
      error => {
        this.submitted = false;
        console.log();
      });
  }

  create(details) {
    this.itemCategService.create(details)
    .pipe(first())
    .subscribe(
      data => {
        this.submitted = false;
        this.dialogRef.close();
      },
      error => {
        this.submitted = false;
        console.log();
      });
  }

}
