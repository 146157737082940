
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ItemCategService, UsersService, UserTypeService } from '../../../_admin-services';
import { first } from 'rxjs/operators';
import { id } from '@swimlane/ngx-charts/release/utils';

import * as $ from 'jquery';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class UserFormComponent implements OnInit {
  @ViewChild('file', { static: false }) file;
  public files: Set<File> = new Set();
  mform: FormGroup;

  submitted = false;
  details: any;
  userType = [];
  primaryId: any;
  strUrl: any[] = [];

  progress;
  canBeClosed = true;
  primaryButtonText = 'Add Item';
  showCancelButton = true;
  uploading = false;
  uploadSuccessful = false;


  constructor(
    public dialogRef: MatDialogRef<UserFormComponent>,
    private userSerive: UsersService,
    private userTypeService: UserTypeService,
    private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data) {
    this.details = data;

    this.primaryButtonText = (this.details) ? 'Edit Item' : 'Add Item';

    if(data){
      this.primaryId = data.id;
    }
  }

  ngOnInit() {
    if (this.details) {
      this.getUser();
    }

    this.getuserTypeService();

    this.mform = this.formBuilder.group({
      usertype_id : [(this.details) ? this.details.usertype_id : '', Validators.required],
      username : [(this.details) ? this.details.username : '', Validators.required],
      email_address : [(this.details) ? this.details.email_address : '', Validators.required],
      password : [(this.details) ? this.details.password : '', (!this.details) ? Validators.required : null ],
      firstname : [(this.details) ? this.details.firstname : '', Validators.required],
      middlename : [(this.details) ? this.details.middlename : '', Validators.required],
      surename : [(this.details) ? this.details.surename : '', Validators.required],
      contact_no : [(this.details) ? this.details.contact_no : '', Validators.required],
      age : [(this.details) ? this.details.age : '', Validators.required],
      gender : [(this.details) ? this.details.gender : '', Validators.required],
      address : [(this.details) ? this.details.address : '', Validators.required]
    });

  }

  getuserTypeService() {
    this.userTypeService.getAll().subscribe(data => {
      const d: any = data;
      this.userType = d.data.items;
    });
  }

  get f() { return this.mform.controls; }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.strUrl.push(event.target.result);
        };
        reader.readAsDataURL(files[key]);
      }
    }
  }

  removeImg(rfile) {
    this.files.delete(rfile);
    this.strUrl.splice(rfile, 1);
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  onSubmit() {

    this.submitted = true;
    if (this.mform.invalid) {
      alert('Pls fillup all required files');
      return;
    }

    if (this.files.size === 0 && !this.details) {
      alert('Images is empty');
      return;
    }
    const data = {
      usertype_id : this.f.usertype_id.value,
      username : this.f.username.value,
      email_address : this.f.email_address.value,
      password : this.f.password.value,
      firstname : this.f.firstname.value,
      middlename : this.f.middlename.value,
      surename : this.f.surename.value,
      contact_no : this.f.contact_no.value,
      age : this.f.age.value,
      gender : this.f.gender.value,
      address : this.f.address.value,
      has_file : true
    };

    if (this.details) {
      this.update(data);
    } else {
      this.create(data);
    }
  }

  getUser() {
    this.userSerive.get(this.primaryId)
      .subscribe(data => {
        const d: any = data;
        this.details = d.data;
        this.details.items[0].imgProfile.forEach(d => {
          console.log(d.file)
          this.strUrl.push(d.file);
        });

      });
  }

  update(data) {
    this.userSerive.update(data, this.files, this.primaryId)
    .pipe(first())
    .subscribe(
      data => {
        this.submitted = false;
        this.dialogRef.close();
      },
      error => {
        this.submitted = false;
      });
  }

  create(data) {
    this.userSerive.create(data, this.files)
    .pipe(first())
    .subscribe(
      data => {
        this.submitted = false;
        this.dialogRef.close();
      },
      error => {
        this.submitted = false;
      });
  }

}
