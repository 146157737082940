import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '../shared/print.service';
import { OrderService } from 'src/app/_admin-services';
import * as converter from 'number-to-words';

@Component({
  selector: 'app-print-or',
  templateUrl: './print-or.component.html',
  styleUrls: ['./print-or.component.scss']
})
export class PrintOrComponent implements OnInit {

  mData: any
  mUser: any
  numWord: string = ""

  constructor(private route: ActivatedRoute, private printService: PrintService, private orderService: OrderService) { }

  ngOnInit() {
    this.mUser = JSON.parse(localStorage.getItem('currentUser'))
    let orderNo = this.route.snapshot.params['ord_no']
    this.orderService.getOrderTrans(orderNo).subscribe(
      res => {
        this.mData = res
        this.numWord = converter.toWords(this.mData.data.items[0].total_amt)
        console.log('PrintOrComponent', this.mData)
        this.printService.onDataReady()
      }
    )
  }

}
