import { ApisService, saveFormInfo } from './../shared/apis.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTP_API_URL } from '../shared/apis.service';
import { id } from '@swimlane/ngx-charts/release/utils';

@Injectable({
  providedIn: 'root'
})
export class PaymentFormService {

  constructor(private service: ApisService) { }

  getForm(){
    return this.service.get(`${HTTP_API_URL}payment-form-info`)
  }

  saveInfo(body: any){
    return this.service.bodyPut(saveFormInfo, body)
  }

}
