import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

import { SettingService } from '../../_admin-services';
import { first } from 'rxjs/operators';
import { id } from '@swimlane/ngx-charts/release/utils';

@Component({
  selector: 'app-setting-img-modal-add',
  templateUrl: './setting-img-modal-add.component.html',
  styleUrls: ['./setting-img-modal-add.component.scss']
})
export class SettingImgModalAddComponent implements OnInit {

  @ViewChild('file', { static: false }) file;
  public files: Set<File> = new Set();
  mform: FormGroup;
  itemCategory = [];
  description: any[] = [];
  strUrl: any[] = [];
  currentImages: any[] = [];
  isView = false;
  uploading = false;
  isSubmit = false;

  uploadSuccessful = false;
  submitted = false;
  img_type = '';
  constructor(
    public dialogRef: MatDialogRef<SettingImgModalAddComponent>,
    private settingService: SettingService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {

      this.img_type = data;
  }

  ngOnInit() {
  }
  addFiles() {
    this.file.nativeElement.click();
  }

  onSubmit() {

    this.isSubmit = true;
    const body = {
      has_file : true,
      file_type : this.img_type,
    };

    this.settingService.uploadFiles(body, this.description, this.files)
    .pipe(first())
    .subscribe(
      data => {
        this.isSubmit = false;
        this.dialogRef.close();
        console.log(data);
      },
      error => {

      }
    );

  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.strUrl.push(event.target.result);
        };
        reader.readAsDataURL(files[key]);
      }
    }
  }

  removeCurrentImg(i) {
    this.currentImages.splice(i, 1);
    this.description.splice(i, 1);

  }

  removeaddesImg(i) {
    this.files.delete(i);
    this.strUrl.splice(i, 1);
  }

  isObjectEmpty(Obj: any) {
    for (const key in Obj) {
      if (Obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }


}
