import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { SettingService } from '../../_admin-services';
import { first } from 'rxjs/operators';
import { id } from '@swimlane/ngx-charts/release/utils';

@Component({
  selector: 'app-setting-img-modal',
  templateUrl: './setting-img-modal.component.html',
  styleUrls: ['./setting-img-modal.component.scss']
})
export class SettingImgModalComponent implements OnInit {

  mform: FormGroup;

  submitted = false;
  details: any;

  constructor(
    public dialogRef: MatDialogRef<SettingImgModalComponent>,
    private settingService: SettingService,
    private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data) {
    this.details = data;
  }

  ngOnInit() {
    console.log(this.details)
    this.mform = this.formBuilder.group({
      description: [(this.details) ? this.details.description : '']
    });
  }

  get f() { return this.mform.controls; }

  onSubmit() {

    if (this.mform.invalid) {
      alert('Pls fillup all required files');
      return;
    }

    const d = {
      description: this.f.description.value
    };
    // updateImageDesc

    this.settingService.updateImageDesc(this.details.id, d).subscribe(data =>{
      console.log(data);

      this.dialogRef.close();
    })

  }

}
